.notification_container {
    width: 350px;
    display: flex;
    max-width: auto;
    justify-content: start;

}

.notification_container .logo {
    width: 50px;
    height: 50px;
    margin: 10px 10px 10px 0px;
    margin-left: 5%;
}

.notification_container .logo img {
    width: -webkit-fill-available;
    object-fit: contain;
}

.notification_container .content p {
    color: #000000;
    margin: 2px 0px;
    font-size: 13px;
    margin-left: 3vh;
}