.Counts {
    margin: 12px 0 0 0;
}

.queueicon {

    height: 40px;
    width: 40px;
}

.imgpadding {
    display: flex;
    margin: 5px 0;
}


.imgpadding p {
    margin: 8px 0 0 7px;
    font-size: 15px;
}

.countsdtat {
    margin: 0 0 0 130px;
    position: fixed;
}

.data_column {
    padding: 10px;
}

.border_line {
    border-right: 4px solid #707070;

}

.data_row {
    margin: 10px 0;
}

.dropdown_button {
    margin-top: 0 !important;
}

.appointment_name_details {
    font-size: 16px;
}

/* ==================testing===== */




/*
.coverflow__cover__25-7e {
    border-radius: 50%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    height: 150px;
    width: 150px;
    margin: auto;
}

.coverflow__figure__3bk_C {
    backface-visibility: hidden;
    border: none;
    box-shadow: none;
    width: 15% !important;
}*/

.coverflow__cover__25-7e {
    border-radius: 50%;
    width: 55%;
    box-shadow: none;
    margin: auto;
}

.coverflow__figure__3bk_C {
    backface-visibility: hidden;
    border: none;
    box-shadow: none;
    padding: 0px 20px;

}

.coverflow__arrowWrapper__3qbYP {
    background-color: white
}

.coverflow__text__39hqd {
    padding: 0;
}

.queue_profile_details_prescription {
    border-bottom: none;
}

.coverflow__text__39hqd {
    background-color: var(--app-theme) !important;
    color: white;
    width: 10px;
    margin: auto;
}

.coverflow__cover__25-7e img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    /* object-fit: contain; */
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.coverflow_label {
    background-color: var(--app-theme);
    color: white;
    height: 20px;
    width: 20px;
    text-align: center !important;
    /* margin-left: 60%; */
    margin-left: 107%;
    /* margin-left: 80%; */
    margin-top: -10px;
    transform: scale(1);
}


.slide_main_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    /* Customize the height as per your needs */
    background-color: #f0f0f0;
    border: 1px solid #ccc;
}

.slide_div {
    text-align: center;
    margin-top: 2rem;
    opacity: 0.5;
    transform: scale(0.6);
}

.slide_div img {
    border: 1px solid var(--app-theme);
    /* height: auto;  */
    height: 185px;
    /* Adjust the height and width as needed */
    width: 185px;
    border-radius: 50%;
    /* This makes the image round */
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.slide_div.active {
    opacity: 1;
    transform: scale(1);
    margin-top: 50px;
}

.slide_div.completed_active {
    /* opacity: 0.5;
    transform: scale(1); */
    opacity: 0.5 !important;
    transform: scale(0.6);
}

.slide_div.active img {
    box-shadow: 0px 0px 20px 0px #00e1d2;
    height: 125px;
    width: 125px;
    margin-top: 5px;
    margin-left: 8px;
}

.slide_divs {
    text-align: center;
    margin-top: 2rem;
    /* opacity: 0.5; */
    transform: scale(0.6);
}

.slide_divs img {
    border: 1px solid var(--app-theme);
    height: 185px;
    /* Adjust the height and width as needed */
    width: 185px;
    border-radius: 50%;
    /* This makes the image round */
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.slide_divs.active {
    opacity: 1;
    transform: scale(1);
    margin-top: 50px;
}

.slide_divs.completed_active {
    opacity: 0.5 !important;
    transform: scale(0.6);
}

.slide_divs.active img {
    box-shadow: 0px 0px 20px 0px #00e1d2;
    height: 125px;
    width: 125px;
    margin-top: 5px;
    margin-left: 8px;
}

/* .swiper {
    height: 300px;
} */

.next_slide {
    opacity: 1;
}

.slide_appopintment_type {
    height: 25px !important;
    width: 25px !important;
    position: relative;
    top: 100px;
    right: 80px;
}

.swiper-slide-active {
    z-index: 1;
}

.active .coverflow_label {
    /* margin-left: 55%; */
    margin-left: 75%;
}

.swiper-slide {
    /* width: 110px !important; */
    width: 80px !important;
}

/* .swiper-slide {
    z-index: 0 !important;
} */

.swiper-slide:has(.active) {
    z-index: 9999 !important;
}

.active .slide_appopintment_type {
    /* top: 80px !important;
    right: 50px !important; */
    top: 15px !important;
    right: -26px !important;
}

.swiper-slide .slide_appopintment_type {
    /* top: 80px !important;
    right: 50px !important; */
    top: 10px;
    right: -53px;
}

.total_appointment_count {
    display: flex;
}

.total_count_value {
    margin-left: 13px;
}

.clinic_Queue {
    height: 35vh;
}

.queue_page .css-1oqqzyl-MuiContainer-root {
    padding-left: 24px;
    padding-right: 4px;
}

/* 
.swiper-wrapper {
    transform: translate3d(-30px, 0px, 0px) !important;
} */

/* .queue_card.css-bhp9pd-MuiPaper-root-MuiCard-root {
    overflow: auto !important;
} */

.queue_swiper .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
    /* overflow: overlay; */
    width: 118% !important;
}