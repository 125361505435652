.deal_card {
    border: 3px dashed var(--app-theme);
    padding: 25px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 12px #00000027;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-bottom: 15px;
}

.deal_card .deal_header {
    font-size: 16px;
    color: #000000;
    opacity: 100%;
    font-weight: 600;
    margin-bottom: 9px;
}

.deal_card .deal_data {
    font-size: 16px;
    color: #707070;
    opacity: 100%;
}

.deal_off_img {
    width: fit-content;
    position: relative;
}

.deal_off_img div {
    position: absolute;
    left: 6px;
    font-size: 11px;
    top: 28px;
    color: #ffffff;
    width: 70px;
    text-align: center;
}

.pos_rel {
    position: relative;
}

.deal_edit {
    position: absolute;
    top: 26px;
    right: 0px;
    cursor: pointer;
}

.dialog_header {
    display: flex;
    padding: 10px;
    background-color: var(--app-theme);
}

.dialog_header .dialog_title {
    color: #fff;
    font-size: 18px;
    font-family: TitilliumWeb-Regular;
    text-transform: uppercase;
}

.dialog_header img {
    width: 15px;
    cursor: pointer;
}

.deal_option_group {
    display: flex;
}

.deal_strick {
    /* position: absolute;
    top: 22px;
    left: 75px;
    color: #707070; */
    /* position: absolute;
    top: 22px;
    left: 75px;
    color: #707070; */
    position: absolute;
    top: 50%;
    left: 100px;
    color: #707070;
    transform: translate(0%, -50%);
}

/* success model design start */
.success_model_body .icon {
    width: fit-content;
    margin: 15px auto;
}

.success_model_body .icon img {
    /* width: 100px; */
    width: 80px;
}

.success_model_body .title {
    color: var(--app-theme);
    /* font-size: 17px; */
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.success_model_body .content {
    color: #595959;
    /* font-size: 14px; */
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px;
}

.css-13maa2j {
    color: var(--app-theme);
}

/* success model design end */
/* input[type="date"].form-control:focus,
input[type="date"].form-control:hover,
.input121:focus,
.input121:hover {
    border: unset !important;
    outline: unset !important;
} */

.input121 {
    border: none !important;
    padding: 0px;
    margin-left: 110px;
    height: 10px;
    margin-top: -20px;
    width: 18px;
    outline: none !important;
    background: none;
    position: absolute !important;
    top: 0;
    right: 0;
}

/* .Date_Picker input[type="date"] {
    border: none !important;
    outline: none !important;
} */
.input121 .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
}

.input121 .MuiOutlinedInput-notchedOutline .css-igs3ac {
    border: none !important;
    outline: none !important;
}

.deal_active_label {
    margin: 0px 0px 6px 0px;
}

.css-w2o3a9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    padding: 10px 15px !important;
}

.error_text {
    color: #ff0068;
    font-size: 12px;
}
.page_head_date_container{
    display: flex;
    align-items: center;
    margin: 0 0.3rem;
    padding-bottom: .5rem;
}