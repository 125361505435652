.model_title {
    background-color: #510f30;
    color: #fff;
    text-transform: uppercase;
    border-radius: 0px 0px;
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    border-radius: 20px 20px 0px 0px;
    padding: 10px;
    justify-content: space-between;
}

.KWD_font {
    font-size: 10px;
    /* position: relative;
    left: 5px; */
}

.close_icon {
    color: #ff0068;
    font-size: 25px;
    cursor: pointer;
}

.Model_crd {
    border-radius: 10px;
}

.Image_container {
    height: 80px;
    width: 80px;
    border-radius: 50px;
    background-color: rgb(134, 137, 137);
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    object-position: center;
}

.Font_size {
    font-size: 13px !important;
    font-weight: 500;
}

.font_size_2 {
    font-size: 13px !important;
    padding: 0px 15px;
    font-weight: 500;
}

.Modal_font {
    color: #a0a0a0;
    font-size: 12px !important;
}

.recpit_crd {
    height: 200px;
    width: 250px;
    border-radius: 5px;
    background-color: #b2afb0;
}

.upload_crd {
    height: 250px;
    width: 550px;
    border-radius: 10px;
    background-color: #DCCFD5;
    border: 1px dashed #510f30;
}

.Downloadimg {
    cursor: pointer;
}

.segment_line {
    color: #510f30;
    font-size: 20px;
    font-weight: 500;
    padding: 8px 25px;
    cursor: pointer;
    padding: 0px;
    margin: 0 10px;
    width: 30%;
}

.revenue_active {
    border-bottom: 2px solid #510f30;
}

.dashed_line {
    width: 70%;
    margin: auto;
    border-bottom: 1px dashed #a0a0a0;
    padding: 5px;
}

.sucs_text {
    font-size: 18px;
    color: #510f30;
    font-family: 'Titillium Web';
}

.succes_msg {
    padding: 20px;
    font-size: 16px;
    font-family: 'Roboto-Regular';
}

.ok_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #511f30;
    color: #fff;
    border-radius: 20px;
    padding: 8px;
    width: 80px;
    cursor: pointer;
}

.upload_butm {
    background-color: #510f30;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}










.apponment_payment_cost .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    width: 350px;
}

.payment_type_title {
    color: #510f30;
    font-size: 16px;
}

.payment_type_title_checkbox {
    color: #510f30;
    font-size: 16px;
    margin-top: 7px;
}

.payment_type_title_text {
    margin-top: 24px;
    color: #510f30;
    font-size: 16px;
}

.currency_value {
    color: #510f30;
    font-size: 12px;
}

.additional_payment_source {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.add-browse-input {
    display: contents;
    padding-bottom: 10px;
}

.MuiInputBase-inputSizeSmall,
.MuiSvgIcon-fontSizeMedium {
    color: var(--app-sub-header) !important;
    border:var(--app-sub-header) !important;
}

.add-browse-input {
    display: contents;
    padding-bottom: 10px;
}

.add-input-browser-li {
    /* background-color: #83af40; */
    color: white;
    background-color: #510f30;
    border-radius: 15px;
    padding: 0px 4px 0px 4px;
    border: 1px solid #510f30;
    margin: 5px;
    display: inline-block;
    font-size: 15px;
}

.over-height {
    height: 55vh;
    overflow: auto;
}
.overflow-height {
    height: auto !important;
}
@media (min-width:1100px) and (max-width:1200px) {
    .overflow-height {
        height: auto !important;
    }
}

@media (min-width:900px) and (max-width:1100px) {
    .overflow-height {
        height: auto !important;
    }


}