.formdiv {
    /* display: inline-block; */
    width: 100%;
    text-align: left;
    position: relative;
    color: #797777;
}

.formdiv .labeltxt {
    color: black !important;
    display: block;
    font-size: 16px;
    font-weight: 500 !important;
}

.formdiv .brdrcls {
    width: 100%;
    padding: 4px;
    border: none;
    outline: 0;
    font-size:14px;
    box-shadow: 0 0 1.5px 0px #ccc;
    border: 1px solid #ccc;
    border-radius: 2px !important;
    font-weight: 400 !important;
}

.formdiv .brdred {
    border: 1px solid red;
}

.formdiv .brdnone .ant-select-selection{
    border: none !important;
}

.formdiv .MuiFormControl-root .MuiTextField-root .MuiFormControl-marginNormal {
    box-shadow: transparent;
}

.formdiv .radiobtncolor {
    color: #82af3f !important;
}

.formdiv .datepickerchnge input {
    width: 100% !important;
    padding: 20px;
    border-radius: 0px;
}

.formdiv .selectbox .ant-select-selection--single {
    height: unset !important;
    padding: 4px;
    outline: 0;
    border-radius: 2px !important;
    border: 0;
    border: 1px solid #ccc;
    box-shadow: 0 0 1.5px 0px #ccc;
    color: #797777;
}

/* .formdiv .selectbox .ant-select-selection--single {
border-color: red;
} */

.formdiv .selectbox {
    /*   z-index: 1400  !important;*/
    width: 100% !important;
    border-radius: 0;
    outline: unset;
}

.formdiv .ant-select-selection__rendered {
    position: relative;
    display: block;
    margin-right: 0;
    margin-left: 11px;
    line-height: 30px;
}

.formdiv .ant-select-search--inline .ant-select-search__field {
    border: none;
    box-shadow: none;
}

.formdiv .ant-select-search__field__wrap input {
    padding-left: 0px;
}

.formdiv .Errormsg {
    font-size: 12px;
    margin-top: 4px;
    color: red;
    height: 15px;
}

.ant-select-dropdown {
    z-index: 1400;
}

.ant-calendar-picker {
    z-index: 1410;
}

.ant-calendar-picker-container {
    z-index: 1410 !important;
}

.formdiv .MuiFormControl-marginNormal {
    margin-top: 0px !important;
    width:100%;
}

/* for datepicker */

.MuiPickersCalendarHeader-dayLabel {
    font-size: 14px;
}

.MuiTypography-colorInherit {
    font-size: 13px;
}

.MuiPickersDay-daySelected {
    background-color: #82af3f !important;
}

.MuiDialogActions-root.MuiDialogActions-spacing .MuiButton-label{
    font-size: 12px;
}

.formdiv .MuiInputBase-inputAdornedEnd{
    color: #797777;
}