.detail_list_view {
  margin: 15px 100px;
  padding: 10px 0 0 0;
  box-shadow: #64646467 0px 0px 12px !important;
}

.view_small_txet {
  font-size: 17px !important;
  color: #000000 !important;
  font-family: Roboto-Regular !important;
}

.right_headers {
  margin-right: 100px;
}

.botton_Add {
  border: none !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  background-color: var(--app-sub-header) !important;
  width: 80px;
  height: 30px;
  text-transform: capitalize !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.botton_Cancel {
  border: none !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  background-color: #ffaa33 !important;
  width: 80px;
  height: 30px;
  text-transform: capitalize !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.botton_Add:hover,
.botton_Cancel:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}

.alert_btn_center {
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

.alert_no_btn {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: #fff !important;
  border-radius: 223px !important;
  padding: 5px 28px !important;
  margin-right: 10px !important;
  background-color: var(--app-theme) !important;
}

.alert_yes_btn {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: #fff !important;
  border-radius: 23px !important;
  padding: 5px 28px !important;
  margin-left: 10px !important;
  background-color: #ff0068 !important;
  outline: none;
  border: none;
}

.image_body_sizes {
  height: 80px;
  width: 80px;
}

.card {
  padding: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px 1px #2f887c75;
  border: none;
  border-radius: 17px;
}
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 50px;
}
.action_btn_view {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5px;
}
.card-body .sub-title {
  color: var(--app-sub-header);
  font-size: 16px;
  padding: 8px;
}
.card-body .sub-value {
  font-size: 14px;
  padding: 10px;
}
.add-hours-prt {
  background: var(--app-sub-header);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 2px 0px;
}
.add-hours-prt:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}
.add-hours-cld {
  background: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-icon-label {
  font-size: 30px;
  color: var(--app-sub-header);
  cursor: pointer;
}
.add-main {
  display: flex;
  justify-content: end;
  padding: 0rem 2rem 1rem 0rem;
}
/* .working-back{ */
/* background: #2F887C75 0% 0% no-repeat padding-box; */
/* box-shadow: 0px 3px 6px #2F887C75; */
/* border-radius: 17px;
  opacity: 0.06; */
/* } */
/* .working-back label {
  color: var(--app-sub-header);
  font-size: 20px;
  padding: 10px;
} */
.content_view_hours {
  margin-top: 90px;
  height: calc(100vh - 13vh);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow-y: auto;
  padding: 15px 12px;
}
.hours-sub-dsn {
  padding: 0px 50px !important;
}
.page-link row_page_details{
  cursor: pointer;
}
.pagination_list_show {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 13px;
}