.drop_img {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  margin-right: 10px;
}
.Service_Size .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  width: 94%;
}
.appointment_types_wrkhrs {
  margin-left: 20%;
}
.slot_clr {
  color: #000000 !important;
  margin-left: 4vh;
}
/* .dropdown_button {
  width: 33.5vh;
} */
