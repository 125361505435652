/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;

} */


.offlinemsg_body{
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 9999999999;
}
.offlinemsg_body_img{
  width: 300px;
  margin: auto;
}
.offlinemsg_body_img img{
  width: 100%;
}
.offlinemsg_content{
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 500;
  color: var(--app-theme);
  font-style: normal;
}
.offlinemsg_content h2{
  font-size: 18px;
}
.offline-content{
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
}

.offline-content h3{
  font-size: 22px !important;
  line-height: 32px;
  color: #E1446A;
  margin: 20px 0px;
}
.offline-content h2{
  font-size: 25px !important;
  line-height: 35px;
  color: var(--app-theme);
  margin: 20px 0px;
  text-align: start !important;
}
.try-again:hover{
  cursor: pointer;
  color: orangered;
}