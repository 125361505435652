.row_content {
    margin: 0%;
}

.header_column {
    display: flex;
    margin: 25px;
}

.upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

input.input_Getstatus {
    width: 40px;
    height: 20px;
    align-items: center;
}

.page_head_date_advertisement {
    /* margin-top: 7px; */
}

.date_left_button,
.date_right_button {
    background-color: #2F887C !important;
    color: white !important;
    margin: 0 !important;
}

.date_button {
    background-color: var(--app-theme) !important;
    color: #ffffff !important;
}

.date_left_button_icon,
.date_right_button_icon {
    padding: 5px 0 !important;
}

.advertisement_list_card {
    height: 170px;
    margin: 45px 0px 10px 0px;
    border: 2px dashed #707070;
    box-shadow: none !important;
}

.status_content {
    height: 53px;
    margin: 0px;
    padding: 0px 25px 0px 25px;
}

.status_result {
    margin: 25px 0px 0px 0px;
    padding: 0px;
}

.list_headings {
    font-size: 14px;
    color: #000000;
    text-align: left;
    margin: 16px 0px 0px 15px;
}

.list_content {
    font-size: 12px;
    color: grey;
    text-align: center;
    margin: 13px 30px;
}

.list_content_datae {
    font-size: 12px;
    color: grey;
    text-align: center;
    margin: 13px 15px;
}

.phone_image {
    height: 50px;
    width: 26px;
    margin: 11px 30px 11px 45px;
}

.advertisementimage {
    height: 60px;
    width: 94px;
    margin: 10px 15px;
    border-style: solid;
    border-color: var(--app-theme);
    padding: 0px;
    object-fit: cover;
}

.advertisement_stepper .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin: 0px !important;
    font-size: 8px !important;
    color: #333333 !important;
    position: relative;
    top: 4px;
}

.advertisement_stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: var(--app-theme) !important;
    height: 19px !important;
    width: 19px !important;
    margin-top: 1px;
}

.advertisement_stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: var(--app-theme) !important;
    height: 19px !important;
    width: 19px !important;
}

.advertisement_stepper .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    /* color: #E3E3E3 !important; */
    color: var(--app-theme) !important;
    height: 19px !important;
    width: 19px !important;
}

.advertisement_stepper .MuiSvgIcon-fontSizeMedium {
    color: var(--app-theme) !important;
}

.status_date {
    margin: 0px;
    padding: 0px;
    font-size: 7px;
    position: relative;
    left: calc(9vw - -4px);
    color: grey;
    bottom: 60px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    height: 19px !important;
}

.body_content {
    padding-top: 80px;
    padding-bottom: 110px;
}

.status_conten_full {
    padding: 0px 55px 0px 60px;
}

.page_head_appoinment {
    margin: 2px 0px 2px 12px;
    font-size: 18px;
}

.advertisement_stepper .MuiSvgIcon-fontSizeMedium {
    color: var(--app-theme) !important;
    height: 19px !important;
    width: 19px !important;
    margin-top: 2px;
    /* border: #CCCCCC !important; */
}

.css-h2cmlr.MuiStepLabel-alternativeLabel {
    margin-bottom: 0px !important
}

.css-2fdkz6.MuiStepLabel-alternativeLabel {
    margin-top: 0px !important;
    font-size: 10px !important;
}

.css-95m0ql {
    display: block !important;
    border-color: #510f30 !important;
    border-top-style: solid !important;
    border-top-width: 3px !important;
}

.ad_stepper .Mui-disabled svg,
.ad_stepper .Mui-active svg {
    border: unset !important;
    border-radius: 100%;
    height: unset !important;
    width: unset !important;
    margin-top: 0;
}

.rejectIcon img {
    width: 17px;
    position: relative;
    top: 0px;
}

.stepper_div_reject_reason {
    font-size: 10px;
    color: #FF0068;
    cursor: pointer;
    text-align: center;
}
.upload_instruction_adv .MuiDialog-paperFullWidth {
    width: 424px;
    height: 192px;
}


.reject_reason_content{
    font-size: 20px;
}
.page_head_date_container{
    display: flex;
    align-items: center;
    }