.cancelled_header_body {
    align-items: center;
}

.cancel_cart {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    align-items: center;
    height: 85px !important;
    margin: 6px 0px 23px 0px;
    width: 100%;
    padding: 0px 10px;
}

.cancel_card_details {
    align-items: center !important;
    justify-content: space-between;
    padding: 9px;
}

.profile_details {
    display: flex;
}

.profile_image {
    height: 65px;
    border-radius: 50%;
    width: 65px;
    border: 1px solid #510F30;
}

.header_name_profile {
    font-size: 13px;
    font-weight: 600;
}

.date_time_details {
    font-size: 11px;
    color: #777777;
}

.fast_image_queue {
    height: 28px;
    width: 28px;
}

.profile_details_cancel {
    font-size: 11px;
    color: #666666;
    margin-top: 7px;
}

.timing_date_details {
    font-size: 11px;
    color: #F85E5E;
    margin-left: 118px;
}

.current_date {
    text-transform: capitalize !important;
}

.cancel_no_data {
    text-align: center;
    color: #000000;
    font-size: 25px;
    /* margin-top: 18px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}
.cancel_no_data_image{
    /* height: 80px; */
    width: 100px;
    text-align: center;
}
.cancel_time,
.cancel_date {
    color: #ff0068;
}