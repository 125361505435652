/* .sub_head {
    margin: 15px 0 0 0;
} */

.sub_head_row {
    margin: 0 !important;
    /* box-shadow: #EAEFF3ED 0 1px 15px; */
}



.sub_head_content {
    height: 50px;
    margin: 0 50px;
    background-color: var(--app-theme) !important;
    box-shadow: #EAEFF3ED 0 1px 15px;
}

.header_column {
    display: flex;
    margin: 25px;
}

.upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.date_left_button,
.date_right_button {
    background-color: #2F887C !important;
    color: white !important;
    margin: 0 !important;
}

.date_left_button_icon,
.date_right_button_icon {
    padding: 5px 0 !important;

}

.date_button {
    background-color: var(--app-theme) !important;
    color: #ffffff !important;
}


.date_left_button_icon,
.date_right_button_icon {
    padding: 5px 0 !important;

}

.clinic_label {
    font-size: 18px;
    font-weight: 600;
    margin-right: 10px;
    color: black;
}

.select_drop {
    width: 150px;
    height: 30px;
    color: black;
    font-size: 16px;
    font-weight: 600;
}

.advertisementtab {
    font-size: 16px;
    padding: 10px 25px;
    color: var(--app-theme);
    border-radius: 5px;
    background: white;
    cursor: pointer;
}

.advertisementtab_active {
    background: var(--app-theme);
    color: white;

}

.location_Title {
    padding: 13px 13px 0px 13px;
    margin: 8px 0px 0px 22px;
}

.location_Getitem {
    padding-left: 27px;
}

.placeholder_Style {
    padding-left: 7px;
    padding-top: 3px;
    border-radius: 3px;
}

.row_content {
    margin: 0%;
}

.content_Tag {
    margin: 0%;
}

.advertisment_List {
    margin-top: 20px;
    color: black;
    font-size: 16px;
}

.content_SubHeadings {
    margin-bottom: 8px;
    color: black;
    font-size: 16px;
}

.get_Input {
    margin: 8px 0px;
    height: 37px;
    width: 140px;
    border-radius: 3px;
    border-color: grey;
}

.submit_Button {
    background: var(--app-theme);
    color: white;
    height: 44px;
    width: 100px;
    border-radius: 50px;
    border: none;
}

.submit_content {
    justify-content: center;
}

.get_input_file {
    width: 90%;
    /* margin-left: 12px; */
    height: 40px;
    border-radius: 5px;
    border-color: #CCCCCC;
}

.select_Icon {
    height: 55px;
    width: 27px;
    margin-left: 10px;
}

input.input_Getstatus {
    width: 40px;
    height: 20px;
    align-items: center;
}

.content_excludeddays {
    margin: 10px 0px 10px 0px;
    color: black;
    font-size: 16px;
}

.input_heading {
    margin: 20px 0px 5px 0px;
    color: black;
    font-size: 16px;
}

.checkbox-content {
    margin: 20px 0px 10px 0px;
}

.fess_content {
    margin-top: 20px;
}

.checkbox_full {
    margin-left: -22px;
}

.upload_icon {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.upload_icon i {
    top: 60px;
    right: 105px;
    position: absolute;
    color: #CCCCCC;
}

.upload_icon img {
    position: absolute;
    top: 27px;
    left: 67px;
}

.advertisement_logo {
    margin-left: 30px;
    --color_fill: #000
}

.Uploading_box {
    position: absolute;
    opacity: 0;
}

.upload_item {
    display: flex;
}

.file_select_report {
    width: 100%;
}

.calendar_full {
    width: 100%;
    height: 300px;
}

.textCalLen12 {
    text-align: start;
    color: #ff0068;
    margin-top: 0px 0px 0px 20px !important;
}

.page_content {
    margin-top: 80px;
}

.page_head_appoinment {
    margin: 2px 0px 2px 12px;
    font-size: 18px;
}

.post_active_btn_ad {
    color: var(--app-theme) !important;
    padding: 0 !important;
}

.post_active_btn_ad .MuiSvgIcon-fontSizeMedium {
    color: var(--app-theme) !important;
    /* border: #CCCCCC !important; */
}

input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}

input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}

.calendar-content {
    height: 35px;
}

/* 
.page_head_date_advertisement {
    margin: 17px 0px 0px 0px;
} */

.upload_image_icon_advertise {
    position: absolute;
    right: 15px;
    top: 43px;
    color: #000;
    height: 25px;
}

.upload_service_advertise {
    position: relative;
}

.upload_label_image {
    margin: 0px 0px 5px 8px;
}

.input_span_advertisement {
    margin-top: 20px;
    display: block !important;
    margin-bottom: 9px !important
}

.upload_title {
    display: flex;
    margin-bottom: 10px;
}

.upload_title img {
    margin-left: 10px;
    cursor: pointer;
}

.placeTopADs {
    padding: 0px 0px 0px 1rem;
}

.placeTopADsRd {
    margin: 1rem 0px 0px 1rem;
}

.success_title {
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 10px;
}

.uploadins_head {
    font-size: 18px;
    font-weight: 500;
    color: white;
    margin-bottom: 0px;
}

.upload_instruction_full {
    background-color: #510F30;
    box-shadow: 0 0 3.5px 0 #ccc;
    border-bottom: 1px solid #ccc;
    /* height: 47px; */
    display: flex;
    align-items: center;
    padding: 20px !important;
    justify-content: space-between;
}

.upload_ins_close {
    width: 15px;
    cursor: pointer;
}

.green_upload_container {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.upldinsicon {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #ff0068;
}

.uploaddIns_div {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
}

.ins_content {
    font-size: 16px;
    margin-left: 10px;
}

.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
    padding: 20px 24px !important;
}

/* .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0px !important;
} */

.css-1ty026z {
    padding: 20px 24px !important;
}

.css-ohyacs {
    padding: 0px !important;
}

.exclude_days {
    width: 375px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.view_more {
    font-size: 12px;
    color: #510F30;
    margin-top: 5px;
}

.view_more_label {
    font-size: 14px;
    border-radius: 20px;
    background: #510F30;
    color: white;
    padding: 5px 6px;
    margin: 5px;
}

.value_lbl {
    margin-top: 5px;
    color: red;
    font-size: 12px;
    /* margin-left: 50px; */
}

.add_btn_adv {
    background-color: #510F30;
    color: white;
    padding: 4px 16px;
    border: 1px solid var(--app-theme);
    border-radius: 30px;
    /* margin: 0px 0 0 6vw; */
    cursor: pointer;
    width: 150px;
}


.payment_type_body {
    display: flex;
    justify-content: space-around !important;
}

.payment_type_body .payment_img_mastercard {
    width: 100px;
    height: 82px;
    margin: 18px 10px 10px 10px;
    object-fit: inherit;
    padding: 2px 4px;
    border-radius: 5px;
    cursor: pointer;
}

.payment_type_body .payment_img_knet {
    width: 100px;
    height: 80px;
    margin: 20px 10px 10px 10px;
    object-fit: contain;
    padding: 2px 4px;
    border-radius: 5px;
    cursor: pointer;
}

.payment_type_body .payment_img_mastercard:hover,
.payment_type_body .payment_img_knet:hover {
    box-shadow: #ddd 0px 0px 9px 9px;
}

.tick_mark {
    height: calc(100vh - 450px);
    width: auto;
    background-repeat: no-repeat;
}

.cross_mark {
    fill: red !important;
    font-size: 12rem !important;
}

.ticket_table_success {
    z-index: 9999 !important;
}

.popup_content ul {
    list-style-type: none;
    padding: 10px;
}

.popup_content ul li {
    font-size: 12px;
    padding: 5px 0px;
    color: #2c2c2c;
}

.payment_status {
    background-color: #FFA447;
    color: #ffffff;
    padding: 4px 30px;
    border-radius: 4px;
    margin: 18px;
}

.payment_type_header {
    background-color: #510F30;
    color: #ffffff;
    padding: 14px;
    font-size: 18px;
}

.advertisement_logo {
    margin-left: 30px;
    --color_fill: #000;
    height: 50px;
}

.image_dis {
    margin: "9px 15px 0px 20px";
}

.page_head_date_container {
    display: flex;
    align-items: center;
}

.add_btn_post {
    background-color: #510F30;
    color: white;
    padding: 7px 16px;
    border: 1px solid var(--app-theme) !important;
    border: none;
    border-radius: 30px;
    margin: 8px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
}