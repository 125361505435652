/* .segment_row {
  margin-top: 35px;
} */

.segment {
  margin: 10px;
  width: 54%;
}

.month,
.quarter,
.year {
  margin: 10px 0;
}

.date_row {
  margin-top: 16px;
}

.left_button_revenue {
  color: #264000;
}

.right_button_revenue {
  color: #264000;
}

.revenue_doctor_income {
  width: 90%;
  margin: auto !important;
}

.revenue_chart_header {
  font-weight: 600;
  text-align: center;
}

.chart_card {
  width: 100%;
  box-shadow: #5A5A5A2E 0px 5px 15px !important;
  padding: 20px;
}

.chart_barcharts {
  margin: 20px;
}

.fine {
  margin-bottom: 61px;
}

.amount_details_revenue {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  margin-bottom: 17px;
}

.check_box_revenue {
  display: flex;
  gap: 15px;
  align-items: center;
}

.tom_revenue {
  height: 17px;
  width: 17px;
  border: 2px solid #264000;
  background-color: #264000;
}

.doctor_revenue {
  height: 17px;
  width: 17px;
  border: 2px solid var(--app-theme);
  background-color: var(--app-theme);
}

.doughnut_chart_row {
  margin-top: 2%;
}

.doughnut_revenue {
  display: flex;
  justify-content: center;
}

.dived_total_count {
  display: flex;
  gap: 30px;
  justify-content: center;
}

.total_slots_revenue {
  font-weight: 600;
  text-align: center;
}

.total_slots_revenue span {
  font-weight: 500;
  text-align: center;
  color: grey;
}

.revenue_booked_details {
  display: flex;
}

.doughnut_available {
  border: 3px solid #264000;
  margin: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
}

.doughnut_booked {
  border: 3px solid var(--app-theme);
  margin: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;

}

.revenue_available_details {
  display: flex;
}

#dongChart {
  width: 300px !important;
  height: 300px !important;
}

.chartjs-tooltip {
  display: none !important;
}

.revenue_under_line {
  border-top: var(--bs-border-width) dashed;
  --bs-border-width: 2px;
}

/* .content_view {
  padding: 0 !important;
} */

.chart_id {
  width: 89%;
  height: 309px;
}

.bookingChartDiv {
  width: 50%;
  margin: auto;
  box-shadow: #5A5A5A2E 0px 5px 15px !important;
  padding: 20px;
  margin-top: 20px;
  height: 360px;
  margin-bottom: 30px;
}

.revenue_lbl {
  width: 50%;
  margin: auto;
  margin-top: 20px;
}

.revenue_checkLbl div {
  background: var(--app-theme);
  color: var(--app-theme);
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_checkLbl {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.revenue_checkPayable div {
  background: #00E8D2;
  color: #00E8D2;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_checkPayable {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.total_revenue div {
  background: #510F30;
  color: #510F30;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.total_revenue {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.revenue_checkreceivable div {
  background: #F8E0D8;
  color: #F8E0D8;
  margin-right: 3px;
  width: 12px;
  height: 8px;
  border: 2px solid black;
}

.revenue_checkreceivable {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0px 24px 10px 0px;
}

.revenue_Receivable div {
  background: #00A49A;
  color: #00A49A;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_Receivable {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.revenue_checkpayablesixty div {
  background: #F49C7A;
  color: #F49C7A;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_overdue div {
  background: #FF0068;
  color: #FF0068;
  margin-right: 3px;
  width: 12px;
  height: 12px;
}

.revenue_checkpayablesixty {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.revenue_overdue {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.revenue_checkOverDue div {
  background: #510F30;
  color: #510F30;
  margin-right: 3px;
  width: 12px;
  height: 8px;
}

.revenue_checkOverDue {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 30px 50px 0px 50px;
}

.revenue_back_icon {
  display: flex;
  justify-content: end;
  align-items: baseline;
  transform: rotate(3.142rad);
}

.revenue_segment_change {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.revenue_back_icon img {
  cursor: pointer;
}

.revenue_segment_icon img {
  cursor: pointer;
}

.revenue_segment_icon {
  display: flex;
  justify-content: space-between;
}

.receivable_legents {
  margin: auto;
  width: 80% !important;
  margin-top: 40px;
}

.receivable_legents div {
  margin-bottom: 0px;
}



/* ==============================Revenue Card New Design ========================= */
.revenue_card_container {
  margin: 0px 20px 20px 20px;
  padding: 15px 15px 0px 15px;
  border-radius: 6px !important;
  text-align: center;
  font-family: 'Roboto-Regular';
}

.revenue_card_container_longtext {
  margin: 0px 20px 20px 20px;
  padding: 15px 0px 0px 0px;
  border-radius: 6px !important;
  text-align: center;
  font-family: 'Roboto-Regular';
}

.revenue_card_container_online {
  background: #510F30 !important;
  color: #FFFFFF !important;
  cursor: pointer;
}

.revenue_card_container_clinic {
  background: #00A49A !important;
  color: #FFFFFF !important;
  cursor: pointer;
}

.revenue_card_container_insurance {
  background: #F49C7A !important;
  color: #FFFFFF !important;
  cursor: pointer;
}

.revenue_card_container_revenue {
  background: #00E8D2 !important;
  color: black !important;
  cursor: pointer;
}

.revenue_card_container_incentive {
  background: #F12C74 !important;
  color: white !important;
  cursor: pointer;
}

.revenue_card_container_tomshare {
  background: #F49C7A !important;
  color: #510F30 !important;
  cursor: pointer;
}

.revenue_card_container_ins_dection {
  background: #00A49A !important;
  color: #510F30 !important;
  cursor: pointer;
}

.revenue_card_container_income {
  background: #F8E0D8 !important;
  color: #510F30 !important;
  cursor: pointer;
}

.revenue_card_head {
  font-size: 18px;
}

.revenue_card_head_insurance {
  font-size: 15px;
}

.revenue_card_content {
  font-size: 26px;
}

.revenue_card_content span {
  font-size: 16px;
}

.revenue_amount_line {
  margin: 5px;
}

.revenue_trans_content {
  font-size: 18px;
}

.revenue_trans_content span {
  font-size: 16px;
}

.card-count {
  text-align: center;
  margin-bottom: 5px;
}



.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.grid-container-settlement {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 50px;
}

.grid-item {
  padding: 10px;
}

.grid-item-hide {
  padding: 10px;
  opacity: 0.2;
}

.revenue_income_chart {
  border-radius: 10px;
  box-shadow: 0px 5px 15px #5A5A5A2E;
  width: 95%;
  margin: 30px !important;
  overflow: hidden;
}

.revenue_income_subheading {
  padding: 10px 10px 10px 25px;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px 5px 0px 0px;
  margin-left: 15px;
  align-items: center;
}

.revenue_income_dynamic {
  font-size: 19px;
  color: #510F30;
  margin-bottom: 0px;
}

.side_bar_heading {
  font-size: 17px;
  color: #510F30;
  margin-bottom: 0px;
}

.sidebar_div .css-dip3t8 {
  background: none !important;
}

.sidebar_div .css-z5rm24 {
  background: none !important;
}

.sidebar_div .css-1wvake5 {
  border-right-style: none !important;
}

.sidebar_div .css-edu7i6 {
  background: none !important;
}

.card_chart_view {
  margin-top: 20px;
}

.sidebar_container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 1px 3px 2px 0px #31313126;
  padding-top: 25px;
  height: 120vh;
}


.revenue_paid_online .css-bhp9pd-MuiPaper-root-MuiCard-root {
  background: #F8F8F8;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px 5px 0px 0px;
  padding: 25px;
  margin: 30px 0px 30px 15px;
}

.revenue_income_heading_content .css-11lq3yg-MuiGrid-root {
  width: 99%;
}

.revenue_payment_type_header label {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.revenue_payment_type_header span {
  font-size: 12px;
  font-weight: 600;
  color: #333333;
}

.revenue_payment_type_body label {
  font-size: 16px;
  color: #666666;
}

.revenue_card_container_settlemet {
  margin: 0px 20px 20px 20px;
  padding: 15px 30px 0px 30px;
  border-radius: 6px !important;
  font-family: 'Roboto-Regular';
}

.revenue_card_head_settlement {
  font-size: 18px;
  text-align: center;
}

.card_content_settlement {
  display: flex;
  justify-content: space-around;
}

.settlemet_received {
  text-align: center;
}

.revenue_total_incomediv {
  text-align: end;
}

.revenue_total {
  background: #510F30;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
}

.revenue_total_incomediv span {
  font-size: 12px;
}

.revenue_total_settlement {
  border: 2px solid #510F30;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  color: #510F30;
  font-size: 16px;
  padding: 10px;
  font-weight: 600;
}