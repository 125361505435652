.Logo {
    height: 15px;
    margin-top: 3px;
}

.ViewMore {
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: relative;
    left: 8px;
}

.KWD_font {
    font-size: 10px;
    /* position: relative;
    left: 5px; */
}

.receivable_card {
    width: 70%;
    background: rgb(252, 169, 136);
    background: linear-gradient(112deg, rgba(252, 169, 136, 1) 5%, rgba(244, 156, 122, 1) 50%, rgba(240, 128, 84, 1) 100%);
    border-radius: 12px;
    transition: 0.3s;
    cursor: pointer;
}

.payable_card {
    width: 70%;
    background: rgb(0, 164, 154);
    background: linear-gradient(112deg, rgba(0, 164, 154, 1) 5%, rgba(0, 164, 154, 1) 39%, rgba(8, 215, 202, 1) 100%);
    border-radius: 12px;
}

.underline_row {
    border-bottom: 1px solid;
    color: #fff;
    margin: 0px;
}

.ununderline_row {
    color: #fff;
    margin: 0px;
}

.Due_day {
    font-size: 10px;
    color: #ff0068;
}

.VendorDetailsDiv tbody th,
.VendorDetailsDiv tbody td {
    width: 100px !important;
}

.Scrool_bar_dash {
    /* overflow-y: auto !important; */
    /* overflow-x: auto !important; */
    overflow: hidden;
}

.Member_data {
    width: 70px;
    text-align: start;
    margin-left: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Member_data_additional {
    width: 30px;
    text-align: start;
    /* margin-left: 30px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header_value {
    font-size: 20px;
    text-transform: uppercase;
    color: #510f30;
    align-items: center;
    cursor: context-menu;
    font-family: 'TitilliumWeb-Regular';

}

.header_img {
    height: 22px;
    margin-left: 10px;
}

.Total_button {
    background-color: #510f30;
    color: #fff;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    position: relative;
    bottom: 120px;
    width: auto;
}

.Kwd_gap_font {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: baseline;
}

.kwd_lbl {
    /* text-align: end;
    width: 99%;
    margin: 10px 0;
    font-size: 14px;
    color: rgb(255, 0, 104);
    text-transform: math-auto; */
    text-align: end;
    width: 99%;
    /* margin: 10px 0; */
    font-size: 14px;
    color: rgb(255, 0, 104);
    text-transform: math-auto;
    /* z-index: 1; */
    padding: 10px 20px;
    /* height: 30px; */
    /* background: #f4f4f4; */
    position: relative;
    z-index: 1;
    /* margin-bottom: 10px; */
}
.display_icon {
    /* font-size: 18px;
    color: #707070; */
    width: 84px;
    cursor: pointer;
}
.pointerCrusor {
    color: #ff0068;
    cursor: pointer;
}

.strikeLine {
    text-decoration: line-through;
    margin-top: 1.3rem;
}