/* -------------------- New FLow ---------------------------- */

.dashboard_content {
  padding: 0px 30px;
}

.dashboard_content_full_text {
  color: var(--app-theme);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
  margin-top: 145px;
  font-family: "Roboto-Regular";
  position: absolute;
  top: -17px;
  left: 46px;
}

.dashboard_content_doctor_name_text {
  color: #fff;
  font-weight: 600;
  font-size: 42px;
  margin-top: 145px;
  font-family: "Roboto-Regular";
  position: absolute;
  top: 75px;
  left: 85px;
  z-index: 1;
}

.dashboard_content_name_text {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  margin-top: 145px;
  font-family: "Roboto-Regular";
  position: absolute;
  top: 145px;
  left: 85px;
  z-index: 1;
}

.Dashboard_Image {
  width: 100%;
  height: auto;
  margin-top: 155px;
  position: relative;
}

.Dashboard_Doctor_Image {
  width: 31%;
  height: auto;
  position: absolute;
  top: 66px;
  right: 48px;
}

.dashboard_content_button {
  padding: 7px 25px 7px 10px;
  border: 1px solid var(--app-theme);
  background: var(--app-theme);
  color: #fff;
  border-radius: 5px;
  font-family: "Roboto-Regular";
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.dashboard_content_button:hover {
  background: #3da394;
}

.dashboard_content_button_container {
  padding-top: 23px;
}

.dashboard_content_button_text {
  padding-left: 10px;
  font-weight: 200;
}

.dashboard_content_menus {
  padding-top: 23px;
}

.dashboard_content_menus_card_one {
  padding: 10px;
}

.dashboard_content_menus_card_two {
  padding: 10px;
}

.dashboard_content_menus_card_three {
  padding: 10px;
}

.dashboard_content_menus_card_four {
  padding: 10px;
}

.dashboard_content_card_one {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 95%;
  height: 150px;
  border-radius: 25px;
  margin: 0px 3px;
  border-bottom: 4px solid var(--app-theme);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.dashboard_content_card_two {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 95%;
  height: 150px;
  border-radius: 25px;
  margin: 0px 3px;
  border-bottom: 4px solid #ffc736;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.dashboard_content_card_three {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 95%;
  height: 150px;
  border-radius: 25px;
  margin: 0px 3px;
  border-bottom: 4px solid #ff4e79;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.dashboard_content_card_four {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 95%;
  height: 150px;
  border-radius: 25px;
  margin: 0px 3px;
  border-bottom: 4px solid #008ffb;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.dashboard_content_card_one:hover,
.dashboard_content_card_two:hover,
.dashboard_content_card_three:hover,
.dashboard_content_card_four:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
  background-color: #fff;
}

.dashboard_content_card_text {
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
  font-size: 20px;
  padding-top: 10px;
}

.dashboard_content_card_text_appointments {
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
  font-size: 30px;
  color: #2a7a6f;
  padding-left: 5px;
  padding-top: 30px;
}

.dashboard_content_card_text_appointments_span {
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
  font-size: 25px;
  color: #2a7a6f;
}

.dashboard_content_card_text_services {
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
  font-size: 25px;
  color: #e4b230;
  padding-left: 5px;
  padding-top: 30px;
}

.dashboard_content_card_text_workinghours {
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
  font-size: 25px;
  color: #e1446a;
  padding-left: 5px;
  padding-top: 30px;
}

.dashboard_content_card_text_revenue {
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
  font-size: 25px;
  color: #0d83eb;
  padding-left: 5px;
  padding-top: 30px;
}

.dashboard_content_card_value {
  padding-top: 10px;
  position: relative;
}

.Dashboard_Add_Appointment_Image {
  width: 20px;
  height: 20px;
}

.Dashboard_Appointments_Image {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #2a7a6f;
  right: 30px;
  bottom: 23px;
  padding: 6px;
  border-radius: 4px;
}

.Dashboard_Services_Image {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #e1446a;
  right: 30px;
  bottom: 18px;
  padding: 6px;
  border-radius: 4px;
}

.Dashboard_Hours_Image {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #e4b230;
  right: 30px;
  bottom: 18px;
  padding: 6px;
  border-radius: 4px;
}

.Dashboard_Revenue_Image {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #0d83eb;
  right: 30px;
  bottom: 18px;
  padding: 6px;
  border-radius: 4px;
}

.dashboard_content_appointments {
  padding-top: 45px;
}

.dashboard_table_card_text {
  font-size: 22px;
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
  margin: 0px;
}

.dashboard_table_card_line {
  width: 65px;
  border-bottom: 4px solid #2a7a6f;
  margin: 0px;
  color: var(--app-theme);
}

.dashboard_table_card_line2 {
  width: 49px;
  border-bottom: 4px solid #2a7a6f;
  margin: 0px;
  color: var(--app-theme);
}

.dashboard_table_card {
  box-shadow: -2px 4px 12px 1px #dbdbdb;
  border-radius: 15px;
  height: 350px;
}

.dashboard_next_patient_card {
  box-shadow: -2px 4px 12px 1px #dbdbdb;
  border-radius: 15px;
  height: 350px;
}

.dashboard_table_card_row {
  margin: 0px;
  padding-top: 20px;
}

.dashboard_table_heading_name {
  padding: 25px 20px 0px;
}

.dashboard_table_card_header {
  background-color: var(--app-theme);
  color: #fff;
}

.dashboard_table_card_header_text {
  padding-top: 8px;
}

.dashboard_next_patient_image {
  width: 70px;
  height: 70px;
}

.dashboard_next_patient_header {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
  margin: 0px;
  color: #31897d;
}

.dashboard_next_patient_content {
  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto-Regular" !important;
  margin: 0px;
  color: #000;
}

.dashboard_next_patient_details_row1 {
  padding-top: 25px;
}

.dashboard_next_patient_details_row2 {
  padding-top: 25px;
}

.dashboard_next_patient_details_row3 {
  padding-top: 25px;
}

.walk_model_header_text {
  color: #000;
  font-size: 18px;
  /* font-weight: 600; */
  font-family: "Roboto-Regular" !important;
  margin: 0px;
}

.holder_input_name {
  border: none;
  margin: 10px 0;
  outline: none;
  width: -webkit-fill-available;
  font-size: 16px;
  border: 1px solid var(--app-theme);
  padding: 6px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.walk_model_header_text_valid {
  color: Red;
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
}

.walk_appointment_booking {
  padding-top: 15px;
}

.walk_appointment_booking_button {
  width: fit-content;
  margin: auto;
  padding-top: 35px;
}

.walk_appointment_book_button {
  color: #ffffff !important;
  border-radius: 5px !important;
  background-color: var(--app-theme) !important;
  padding: 8px 30px;
  background: var(--app-theme) 0% 0% no-repeat padding-box;
  border: 1px solid var(--app-theme);
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: auto;
}

.walk_appointment_book_button:hover,
.walk_appointment_cancel_button:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}

.walk_appointment_cancel_button {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  color: var(--app-theme) !important;
  padding: 8px 30px;
  background: var(--app-theme) 0% 0% no-repeat padding-box;
  border: 1px solid var(--app-theme);
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: auto;
}

.walk_model_left_part {
  padding: 0px 25px;
}

.walk_model_right_part {
  padding: 0px 25px;
}

.dashboard_card_content_new {
  margin: 3px 0px 0px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 2px;
  font-size: 16px;
  color: #000;
}

.dashboard_content_row_data {
  background-color: #2a7a6f40 !important;
  color: #000 !important;
}

.dashboard_upcoming_appointment_data {
  overflow-y: auto;
  height: 225px;
}

.no_data_found {
  font-size: 20px;
  font-family: "Roboto-Regular" !important;
  font-weight: 600;
  padding-top: 50px;
  margin: 0px;
}

.dashboard_dialog_header {
  display: flex;
  padding-right: 20px;
  padding-top: 7px;
}

.walk_model_service_part {
  padding: 0px 11px 0px 25px;
}

.dashboard_select_finder {
  display: flex;
  justify-content: space-around;
  padding-top: 25px;
}

.dashboard_select_icon {
  display: flex;
}

.dashboard_warning_helper_txt {
  color: red;
  text-align: center;
  background-color: var(--app-theme);
  padding: 10px;
  border-radius: 5px;
  text-transform: capitalize;
}

.dashboard_helper_txt {
  color: #000;
  padding: 10px 0px 0px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto-Regular" !important;
}

.dashboard_total_slots_top {
  margin-top: 0px;
}

.dashboard_time_sloat {
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard_time_show {
  padding: 6px 10px 6px 15px;
  margin: 0;
  background-color: #510f3040;
  color: var(--app-theme);
  font-size: 13px;
  height: 32px;
  width: 84px;
  border-radius: 3px;
  border-width: 2px !important;
  text-transform: lowercase !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard_time_show:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}

.dashboard_time_disabled:hover {
  transform: none;
  box-shadow: none;
  cursor: not-allowed;
}

.dashboard_check_box_booked {
  height: 15px;
  width: 15px;
  background-color: #fbeae5;
  border: 1px solid red;
  margin: 5px;
}

.booked {
  color: red;
}

.dashboard_check_box_available {
  height: 15px;
  width: 15px;
  background-color: #2a7a6f40;
  border: 1px solid var(--app-theme);
  margin: 5px;
}

.dashboard_check_box_selected {
  height: 15px;
  width: 15px;
  background-color: var(--app-theme);
  border: 1px solid var(--app-theme);
  margin: 5px;
}

.selected,
.available {
  color: var(--app-theme);
}

.dashboard_chart_data {
  box-shadow: -2px 4px 12px 1px #dbdbdb;
  border-radius: 8px;
}

.dashboard_chart_header {
  font-size: 22px;
  font-family: "Roboto-Regular" !important;
  font-weight: 600;
  padding-top: 10px;
  margin: 0px;
}

.dashboard_chart_content_value {
  font-size: 27px;
  font-family: "Roboto-Regular" !important;
  font-weight: 600;
  padding-top: 15px;
}

.dashboard_next_patient_details_head_row4 {
  text-align: center;
  padding: 20px 75px 0px 160px;
  display: flex;
  justify-content: center;
}

.dashboard_next_patient_content_symptoms {
  background-color: #2a7a6f40;
  color: var(--app-theme);
  margin: 0px;
  border-radius: 6px;
  font-size: 20px;
  font-family: "Roboto-Regular";
  width: auto;
  padding: 6px;
  padding: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

@media only screen and (min-width: 1180px) and (max-width: 1500px) {
  .dashboard_content_full_text {
    color: var(--app-theme);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 17px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: -17px;
    left: 46px;
  }

  .dashboard_content_doctor_name_text {
    color: #fff;
    font-weight: 600;
    font-size: 36px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 60px;
    left: 78px;
    z-index: 1;
  }

  .dashboard_content_name_text {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 110px;
    left: 78px;
    z-index: 1;
  }

  .Dashboard_Doctor_Image {
    width: 31%;
    height: auto;
    position: absolute;
    top: 75px;
    right: 48px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1180px) {
  .dashboard_content_full_text {
    color: var(--app-theme);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 17px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: -17px;
    left: 46px;
  }

  .dashboard_content_doctor_name_text {
    color: #fff;
    font-weight: 600;
    font-size: 30px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 50px;
    left: 78px;
    z-index: 1;
  }

  .dashboard_content_name_text {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 100px;
    left: 78px;
    z-index: 1;
  }

  .Dashboard_Doctor_Image {
    width: 31%;
    height: auto;
    position: absolute;
    top: 88px;
    right: 48px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .dashboard_content_full_text {
    color: var(--app-theme);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: -11px;
    left: 38px;
  }

  .dashboard_content_doctor_name_text {
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 50px;
    left: 78px;
    z-index: 1;
  }

  .dashboard_content_name_text {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 87px;
    left: 78px;
    z-index: 1;
  }

  .Dashboard_Doctor_Image {
    width: 31%;
    height: auto;
    position: absolute;
    top: 99px;
    right: 48px;
  }
}

@media only screen and (min-width: 650px) and (max-width: 800px) {
  .dashboard_content_full_text {
    color: var(--app-theme);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: -8px;
    left: 38px;
  }

  .dashboard_content_doctor_name_text {
    color: #fff;
    font-weight: 600;
    font-size: 19px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 40px;
    left: 78px;
    z-index: 1;
  }

  .dashboard_content_name_text {
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 73px;
    left: 78px;
    z-index: 1;
  }

  .Dashboard_Doctor_Image {
    width: 31%;
    height: auto;
    position: absolute;
    top: 106px;
    right: 48px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 650px) {
  .dashboard_content_full_text {
    color: var(--app-theme);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: -8px;
    left: 38px;
  }

  .dashboard_content_doctor_name_text {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 27px;
    left: 48px;
    z-index: 1;
  }

  .dashboard_content_name_text {
    color: #fff;
    font-weight: 500;
    font-size: 8px;
    margin-top: 145px;
    font-family: "Roboto-Regular";
    position: absolute;
    top: 50px;
    left: 48px;
    z-index: 1;
  }

  .Dashboard_Doctor_Image {
    width: 31%;
    height: auto;
    position: absolute;
    top: 117px;
    right: 48px;
  }

  .dashboard_content_button {
    padding: 4px 13px 4px 7px;
    border: 1px solid var(--app-theme);
    background: var(--app-theme);
    color: #fff;
    border-radius: 5px;
    font-family: "Roboto-Regular";
    font-weight: 600;
  }
}

@media only screen and (min-width: 870px) and (max-width: 1100px) {
  .dashboard_content_card_text {
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    font-size: 17px;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 740px) and (max-width: 870px) {
  .dashboard_content_card_text {
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    font-size: 12px;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 580px) {
  .dashboard_content_card_text {
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    font-size: 8px;
    padding-top: 10px;
  }

  .dashboard_content_card_text_appointments {
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    font-size: 17px;
    color: #2a7a6f;
    padding-left: 5px;
    padding-top: 37px;
  }

  .dashboard_content_card_text_appointments_span {
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    font-size: 15px;
    color: #2a7a6f;
  }

  .dashboard_content_card_text_services {
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    font-size: 15px;
    color: #e4b230;
    padding-left: 5px;
    padding-top: 37px;
  }

  .dashboard_content_card_text_workinghours {
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    font-size: 15px;
    color: #e1446a;
    padding-left: 5px;
    padding-top: 37px;
  }

  .dashboard_content_card_text_revenue {
    font-weight: 600;
    font-family: "Roboto-Regular" !important;
    font-size: 15px;
    color: #0d83eb;
    padding-left: 5px;
    padding-top: 37px;
  }

  .Dashboard_Appointments_Image {
    width: 23px;
    height: 23px;
    position: absolute;
    background: #2a7a6f;
    right: 10px;
    bottom: 23px;
    padding: 6px;
    border-radius: 4px;
  }

  .Dashboard_Services_Image {
    width: 23px;
    height: 23px;
    position: absolute;
    background: #e1446a;
    right: 10px;
    bottom: 18px;
    padding: 6px;
    border-radius: 4px;
  }

  .Dashboard_Hours_Image {
    width: 23px;
    height: 23px;
    position: absolute;
    background: #e4b230;
    right: 10px;
    bottom: 18px;
    padding: 6px;
    border-radius: 4px;
  }

  .Dashboard_Revenue_Image {
    width: 23px;
    height: 23px;
    position: absolute;
    background: #0d83eb;
    right: 10px;
    bottom: 18px;
    padding: 6px;
    border-radius: 4px;
  }
}

.main_card_image {
  /* display: flex;
      flex-direction: row; */
  /* background-color: aqua; */
  /* margin-top: 17vh; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.home_card_title {
  margin: 5px 0 0 0;
  color: #000000;
}

.video-icon3 {
  position: absolute;
  bottom: 21px;
}

.card_image {
  border-radius: 10px;
  /* box-shadow: inset 0 0 2px #333; */
  /* border: solid 2px var(--app-theme); */
  border: solid 1px var(--app-theme);
  box-shadow: 0 3px 6px #00000029;
  height: 26vh;
  padding: 1rem;
  width: 16vw;
}

.card_image_content {
  /* margin: 1rem; */
  margin: 5px;
  /* transition: transform 0.5s; */
  cursor: pointer;
  /* box-shadow: inset 0 0 5px #333; */

  position: relative;
}

.image_div {
  /* background-color: aqua; */
  /* height: 205px;
      width: 277px; */
  overflow: hidden;
  /* position: relative;
      z-index: 1; */
}