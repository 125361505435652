/* ==========================walk in SubHeader start================== */
.walkin_header_body {
  align-items: center;
}

/* .dropdown_button .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 0.8em !important;
} */
.select_options {
  min-width: 195px !important;
  min-height: 1.8em !important;
}

.header_date_btn {
  padding-top: 1px !important;
  padding-left: 10px !important;
}

.date_button_right {
  background-color: #510F3040 !important;
  min-width: 34px !important;
  height: 36px;
  color: var(--app-theme) !important;
  margin: 0 10px !important;
}

/* .dropdown_button .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 26px!important;
} */
.current_date {
  background-color: var(--app-theme) !important;
  color: #ffffff !important;
  padding: 6px 23px !important;
}

.date_icon_right {
  font-size: 13px;
  padding: 0 !important;
}

/* ==========================walk in SubHeader end================== */

/* =========================walk In booking start======================= */
.content_view .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: 26px !important;
}

.total_walkin_service {
  text-align: center;
  margin-top: 60px !important;
}

/* ============service start=========== */
.service_consulting_walkin {
  margin-right: 63px;
}

/* ==============service end============= */

/* =======duration and cost start==== */
.walkin_input_header {
  font-weight: 600;
}

.input_duration {
  margin-top: 18px;
}

/* =======duration and cost end==== */

/* =========total slots start======== */
.total_walkin_slots {
  margin: 42px 0px 0px 20px;
}

.walkin_booking_select_time {
  text-align: center;
}

.walkin_time_slots {
  font-weight: 500;
  font-size: 14px;
}

.square_slots_booked {
  height: 17px;
  width: 17px;
  background-color: #FBEAE5;
  border: 1px solid #E12F2F;
  margin-top: 5px;
  border-width: 2px;
}

.walkin_booked {

  color: #E12F2F !important;
  font-size: 13px;
}

.walkin_booked .MuiSvgIcon-fontSizeMedium {
  color: #e12f2f !important;
}

.square_slots_available {
  height: 17px;
  width: 17px;
  background-color: #510F3040;
  border: 1px solid var(--app-theme);
  margin-top: 5px;
  border-width: 2px;
}

.walkin_available {
  color: var(--app-theme) !important;
  font-size: 13px;
}

.walkin_available .MuiSvgIcon-fontSizeMedium {
  color: #e0ebcf !important;
}

.square_slots_selected {
  height: 17px;
  width: 17px;
  background-color: var(--app-theme);
  border: 1px solid var(--app-theme);
  margin-top: 5px;
  border-width: 2px;
}

.walkin_selected {
  color: var(--app-theme) !important;
  font-size: 13px;
}

.walkin_selected .MuiSvgIcon-fontSizeMedium {
  color: var(--app-theme) !important;
  border: #CCCCCC !important;
}

.walkin_total_slots {
  margin-top: 10px !important;
}

.time_sloat {
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.time_show {
  /* padding: 6px 10px; */
  padding: 6px 10px 6px 15px;
  margin: 0;
  background-color: #510F3040;
  color: var(--app-theme);
  font-size: 13px;
  height: 32px;
  width: 84px;
  border-radius: 3px;
  border-width: 2px !important;
  text-transform: lowercase !important;
}

/* ========== total slots end====== */

/* ===========all walkin patient details start============ */
.walkin_input_header_profile {
  font-weight: 600;
  text-align: start;
  margin: 37px 0px 0px 28px;
}

.input_field_walk {
  margin-bottom: 15px !important;
  width: 90% !important;
  border: none !important;
  outline: none !important;
  margin-left: 24px !important;
}

.input_field_walk .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
  border-bottom: 1px solid #000;
  padding: 5px 5px 5px 0px !important;
  border-radius: 0px !important;
  margin-left: 5px !important;
}

.input_field_walk .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 5px 5px 4px !important;
}

/* ===========all walkin patient details end============ */

/* ===================book button start============== */
.walk_book_btn {
  text-align: center;
}

.book_btn {
  margin-top: 50px !important;
  background: var(--app-theme) !important;
  padding: 5px 30px !important;
  border-radius: 20px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
/* ===================book button end============== */

/* ==================walk in success model start=========== */
.success_model_body .icon {
  width: fit-content;
  margin: 15px auto;
}

.success_model_body .icon img {
  /* width: 100px; */
  width: 80px;
  height: 80px;
}

.success_model_body .title {
  color: var(--app-theme);
  /* font-size: 18px; */
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: TitilliumWeb-Regular;
}

.success_model_body .content {
  color: #000000;
  /* font-size: 14px; */
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
  font-family: Roboto-Regular;
}

/* ==================walk in success model end=========== */
.slots_show {
  display: block;
  /* width: 100%; */
  color: grey;
}

/* .walkin_no_available_slots{
width: 100% !important;
text-align: center;
} */
.slot_type_walkin {
  position: absolute;
  margin: 2px 0px 0 83px;
  height: 23px;
  width: 23px;
  border-radius: 15px;
  padding: 3px;
}

.slot_show_walkin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: inherit;
}

.slot_container_walkin {
  display: flex;
}

.slot_unavailable {
  display: block;
  margin-top: 35px;
  color: #666666;
}

.gender_details {
  display: flex;
  align-items: end;
}

.gender_dropdown_align {
  margin-bottom: 7px;
}

.walkin_patient_detials .css-1v4ccyo {
  height: 41px !important;
}

.patient_gender {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}