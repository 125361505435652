.credit_point_img {
  width: 160px;
  height: 120px;
}
.credit_point_imgs {
  height: 40px;
}
.credit_point_card_one {
  padding: 35px 18px;
  display: flex;
  box-shadow: 0px 3px 9px #00000029;
  border-radius: 2px;
}

.credit_point_row_two {
  padding-top: 20px;
}
.credit_point_name {
  color: var(--app-theme);
  margin: 0;
}
.credit_point_member {
  color: #ebeb13;
  font-size: 30px;
  margin: 0;
}
.credit_point_wallet {
  font-weight: bolder;
  font-size: 18px;
  margin: 0;
}
.credit_point_kwd {
  margin: 0;
  color: var(--app-theme);
}
.credit_point_earned {
  margin: 0;
  color: var(--app-theme);
}
.credit_point_card_three {
  text-align: center;
  padding: 25px;
  margin: 0px 0px 0px 215px;
  border-radius: 10px !important;
  border: 1px solid #95989a;
}

.credit_point_kwd span {
  font-size: 25px;
}
.credit_point_row_three {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.credit_point_images {
  width: 80px;
  height: 60px;
  margin-top: 10px;
  border-radius: 60%;
}
.credit_point_card_five {
  width: 100%;
  padding: 10px 10px 0px 10px;
  border: 1px solid #707070;
  border-radius: 10px !important;
}
.credit_point_mobile {
  font-size: 60px;
}
.credit_point_excel {
  margin: 10px 15px;
}
.credit_point_card_name {
  font-weight: bolder;
  padding-top: 30px;
}
.credit_point_date {
  margin: 0;
  color: #959595;
}
.credit_point_img_logo {
  width: 100px;
  height: 80px;
  border-radius: 60%;
}

.credit_point_transfer {
  background: var(--app-theme) !important;
  height: 40px !important;
  left: 52px !important;
  top: 55px !important;
  color: #fff !important;
  border-radius: 50px !important;
}
.input_field_wallet {
  border-bottom: 1px solid #707070 !important;
  width: 100%!important;
}
.input_span_wallet {
  display: block !important;
  margin-bottom: 5px !important;
  color: var(--app-theme) !important;
  font-size: 16PX !important;
  font-family: Roboto-Regular;
}
