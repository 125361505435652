* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/font/ROBOTO/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "TitilliumWeb-Regular";
  src: url("./assets/font/Titillium_Web/TitilliumWeb-Regular.ttf")
    format("truetype");
}

:root {
  --app-theme: #2f887c;
  --app-sub-header: #378c81;
  /* Define a root variable for primary color */
  /* #83af40 Old color code */
}

/* @media screen and (max-width: 1280px) and (max-height:720px) {
  html {
    zoom: 80%;
    .main_card_image{
      background-color: #510F30;
    }
    
  }
} */
/* 
@media screen and (max-width: 1680px) and (max-height:1050px) {
  html {
    zoom: 125%;
    .main_card_image{
    background-color:#ff0068;
    }
  }
}
@media screen and (max-width: 820px) and (max-height:1180px) {
  html {
    zoom: 100%;
    .main_card_image{
    background-color:red;
    }
  }
}
@media screen and (max-width: 1536px) and (max-height:864px) {
  html {
    zoom: 100%;
    .main_card_image{
    background-color:blue;
    }
  }
} */

/* common css start */

.flex_grow {
  flex-grow: 1;
}

.segment_center {
  width: fit-content;
  margin: auto;
}

.content_view {
  margin-top: 90px;
  padding: 10px 50px !important;
  height: calc(100vh - 13vh);
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100%;
  overflow-y: auto;
  padding: 15px 12px;
}
.post-content-view {
  height: calc(100vh - 200px);
}
.content_viewss {
  margin-top: 70px;
  padding: 0px 35px;
  height: calc(100vh - 165px);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow-y: auto;
  padding: 0px 12px;
}

.content_view_appointment {
  height: 70vh;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 22vh;
  padding: 0px 1rem 35px;
}

.service_type_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0px 15px 0px 0px;
}

.warning_helper_txt {
  color: red;
  text-align: center;
  background-color: #ff000030;
  padding: 10px;
  border-radius: 5px;
  text-transform: capitalize;
}

/* common css end */
.btn-primary {
  --bs-btn-color: #000000 !important;
  --bs-btn-bg: none !important;
  --bs-btn-border-color: #000000 !important;
  --bs-btn-hover-color: #000000 !important;
  --bs-btn-hover-bg: none !important;
  --bs-btn-hover-border-color: #000000 !important;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: none !important;
  --bs-btn-active-border-color: none !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: none !important;
  --bs-btn-disabled-border-color: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #31897d33;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--app-theme);
  border-radius: 5px;
  width: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acacac;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #000000 !important;
  background-color: none(--bs-btn-active-bg) !important;
  border-color: #000000 !important;
}

.btn:hover {
  color: #000000 !important;
}

.dropdown_button:hover {
  background-color: none !important;
}

.dropdown_button:active {
  background-color: none !important;
}

.border_bottom {
  border: 0px !important;
  border-bottom: 1px solid gray !important;
}

.page_head {
  margin: 4px 20px;
  font-size: 18px;
}

.btnWithNOSHade {
  all: unset;
}

.botton_Cart_Create {
  color: #ffffff !important;
  border-radius: 25px !important;
  background-color: var(--app-theme) !important;
  padding: 8px 30px;
  background: var(--app-theme) 0% 0% no-repeat padding-box;
  border: 1px solid var(--app-theme);
  /* width: 100px; */
  font-size: 16px;
  font-weight: 500;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.botton_Cart_Create:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}

.btn-close {
  --bs-btn-close-bg: url("../src/assets/icon/Icon\ ionic-ios-close.png") !important;
  --bs-btn-close-opacity: 0.75 !important;
  --bs-btn-close-hover-opacity: 2 !important;
}

.detailsprofile {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.profile_Details {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 20px;
  box-shadow: #00000029 0px 3px 6px;
}

.Model_success_text {
  font-size: 22px;
  margin: 20px 0;
  color: var(--app-theme);
}

.Model_content_text {
  color: #595959;
}

.success_button {
  margin: 0 !important;
}

.Header_Select_Label {
  width: 200px;
  margin-top: 25px;
}

.fontSize10 {
  font-size: 10px;
}

.txtClrg {
  color: #707070;
}

.lowerCaseText {
  text-transform: lowercase;
}

.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--app-theme) !important;
}

.react-calendar {
  height: 65vh !important;
  width: -webkit-fill-available !important;
}

.header_back_button {
  font-size: 28px;
  color: #000000;
  margin-left: 30px;
}

.success_body_size {
  font-family: TitilliumWeb-Regular !important;
  font-size: 20px !important;
  color: var(--app-theme) !important;
}

.success_body_size_alert {
  font-family: TitilliumWeb-Regular !important;
  font-size: 20px !important;
  color: #ff0068 !important;
  padding-top: 10px;
}

.success_msg {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: #595959 !important;
}

.input_span {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: #000000 !important;
}

.success_ok_btn {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: var(--app-theme) !important;
  padding: 5px 30px !important;
  outline: none !important;
  border: none !important;
  border-radius: 23px !important;
}

.success_card {
  padding-top: 10px !important;
}

.error {
  text-align: left;
  margin-top: 0.4rem;
  color: #ff0068;
  font-size: 12px;
  font-weight: bolder;
}

.specialtyimgRow {
  margin: 0;
  width: 100% !important;
}

/* =============== sub header style start =============== */

.sub_header {
  /* padding: 10px 35px; */
  padding: 0 1rem;
  background-color: #ffffff;
  width: 100%;
  height: 10vh;
  position: fixed;
  top: 90px;
  left: 0px;
  border-bottom: 1px solid #dddddd;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 999;
  overflow: hidden;
}

.sub_header .sub_header_body {
  display: flex;
  height: 10vh;
}

.PosZindex {
  position: relative;
  z-index: 3;
}

.sub_header .sub_header_body .back_navigation {
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  margin: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.sub_header .sub_header_body .back_navigation i {
  font-size: 30px;
  /* font-weight: bold; */
  margin-top: -2px;
  color: #000000;
}

.sub_header .sub_header_body .back_navigation span {
  font-size: 18px;
  margin-left: 13px;
  font-family: TitilliumWeb-Regular !important;
  /* color: var(--app-theme); */
  color: #000000;
  margin-top: 2px;
}

.sub_header .sub_header_body .header_right_content {
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
}

.header_right_content .css-1e7cuzv-MuiFormControl-root {
  min-width: none !important;
  margin: 0 !important;
}

.add_btn {
  background-color: #510f30;
  color: white;
  padding: 7px 16px;
  border: 1px solid var(--app-theme);
  border-radius: 30px;
  /* margin: 0px 0 0 6vw; */
  cursor: pointer;
  width: 150px;
  display: flex;
  align-items: center;
}
.add_btnss {
  background-color: #510f30;
  color: white;
  padding: 7px 16px;
  border: 1px solid var(--app-theme);
  border-radius: 30px;
  /* margin: 0px 0 0 6vw; */
  cursor: pointer;
  width: 130px;
  display: flex;
  align-items: center;
}
.add_btn i {
  font-size: 20px;
  margin-right: 10px;
}
.add_btnss i {
  font-size: 20px;
  margin-right: 10px;
}
.header_right_content .css-1869usk-MuiFormControl-root {
  margin: 0 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 !important;
}

.selectPval {
  margin-bottom: 0 !important;
}

/* =============== sub header style end =============== */

/* =============== textbox file upload start =============== */
.fileUpload {
  background: transparent;
  border-radius: 5px;
  color: #0e0e0e;
  font-size: 1em;
  font-weight: bold;
  overflow: hidden;
  padding: 8px;
  position: relative;
  text-align: right;
  width: 100%;
  cursor: pointer;
  border: 1px solid #c4c4c4;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 39px;
  cursor: pointer;
}

.fileUpload span img {
  width: 14px;
  margin: 0px 9px;
}

.fileUpload p {
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 16px;
  font-weight: 200;
  margin: 0px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #707070;
}

/* =============== text box file upload end =============== */

/* =============== Mui css text box inside text css start =============== */
.css-1pnmrwp-MuiTypography-root {
  font-size: 10px !important;
}

/* =============== Mui css text box inside text css start =============== */

.loader_body {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgb(255 255 255 / 85%);
  z-index: 99999999;
}

.css-1idz92c-MuiCircularProgress-svg {
  color: var(--app-theme);
}

.header_row {
  height: 80px;
}

.success_model_body .title {
  color: var(--app-theme);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: TitilliumWeb-Regular;
}

.success_model_body .failedtxt {
  /* color: #ff0068 !important; */
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  /* text-transform: uppercase; */
  margin-bottom: 15px;
  /* font-family: TitilliumWeb-Regular;*/
}

.image_body_sizes {
  height: 80px;
  width: 80px;
}

.Navication-header {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  background-color: #31897d33;
  margin: 1rem 0rem 1.6rem 0rem;
  position: absolute;
  width: 100%;
  top: 11vh;
  /* box-shadow: 0px 3px 6px #2F887C75;
  opacity: 0.6; */
}
.Navication-header label {
  cursor: pointer;
  font-size: 25px;
  color: var(--app-sub-header);
}
.Navication-header i {
  font-size: 25px;
  padding: 0rem 0.5rem;
  color: var(--app-sub-header);
}
.Navication-header img {
  padding: 0rem 0.5rem 0.4rem 0.5rem;
  cursor: pointer;
}
.confirmation_model_body .image_division {
  background: #2f887c;
  border-radius: 50%;
  width: fit-content;
  margin: 2rem auto 1rem;
}
.confirmation_model_body .image_icon {
  height: 70px;
  width: 70px;
  margin: 1.2rem;
}
.confirmation_model_body .confirm_header {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}
.confirmation_model_body .confirm_content {
  color: #595959;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}
.confirm_btn {
  display: flex;
  justify-content: space-around;
  margin: 2rem;
}
.confirm_no_btn {
  font-size: 18px !important;
  font-weight: 600;
  color: var(--app-theme) !important;
  padding: 0.8rem 2rem !important;
  margin-right: 10px !important;
  background-color: #2f887c75;
  border: none;
  border-radius: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.confirm_yes_btn {
  font-size: 18px !important;
  font-weight: 600;
  color: #dc3545 !important;
  padding: 0.8rem 2rem !important;
  margin-right: 10px !important;
  background-color: #f49c7a;
  border: none;
  border-radius: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.confirm_no_btn:hover,
.confirm_yes_btn:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}

.confirm_model {
  width: 100%;
}
