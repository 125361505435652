/* ============ */

/* ===== Header button Style start ====== */

.page_head_date {
  /* margin-top: 7px; */
  /* margin-bottom: 7px; */
}

.date_left_button,
.date_right_button {
  background-color: #2f887c !important;
  color: white !important;
  margin: 0px !important;
  min-width: 42px !important;
  min-height: 42px !important;
  height: 53px !important;
  font-size: 17px !important;
}
.date_left_button {
  border-radius: 10px 0px 0px 10px !important;
}
.date_right_button {
  border-radius: 0px 10px 10px 0px !important;
}
.date_button {
  border-radius: 0px !important;
  padding: 0px 33px !important;
  height: 53px !important;
  font-size: 17px !important;
}

.date_button {
  background-color: var(--app-theme) !important;
  color: #ffffff !important;
  padding: 8px 17px !important;
  text-transform: capitalize !important;
}

.date_left_button_icon,
.date_right_button_icon {
  padding: 5px 0 !important;
}

/* ===== Header button Style End ====== */

.sub_head_content {
  height: 50px;
  margin: 0 20px !important;
  background-color: var(--app-theme) !important;
  box-shadow: #eaeff3ed 0 1px 15px;
}

.today_count {
  color: #ffffff;
  font-size: 17px;
  margin-top: 15px;
}

.circle {
  margin-top: -9px;
  width: 68px;
  height: 80px;
  /* background-color: #ffffff; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--app-theme);
  font-size: 40px;
  cursor: pointer;
  /* margin-left: 50px; */
  margin-right: 30px;
}

.appointment_content {
  margin: 15px 45px;
}

.tabs {
  border-radius: 2px;
  padding: 15px 0 0 10px;
  box-shadow: #00000029 0 3px 9px;
}

.appointment_content_card {
  margin: 10px 0;
}

.Profile_detial {
  display: flex;
}

.Profile_img {
  border: 1px solid var(--app-theme);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  box-shadow: #00000029 0px 3px 6px;
}

.profile_box {
  position: absolute;
}

.Profile_img_type {
  height: 25px;
  width: 25px;
  margin: 44px 0 0 18px;
}

.profile_row_text {
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  width: 100%;
}

.profile_name {
  width: 160px;
}

.profile_names {
  margin-top: 0 !important;
}

.profile_year {
  margin: -15px 0 0 0;
}

.profile_years {
  margin: 0;
}

.profile_time {
  margin: -15px 0 -15px 0;
  /* text-transform: lowercase; */
}

.profile_years,
.profile_times {
  font-size: 12px;
  color: #777777;
  text-transform: lowercase;
}

.profile_names {
  font-size: 16px;
  color: #333333;
}

.row_profile {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.check_in,
.referral,
.prescription,
.paid,
.report,
.history,
.check_out {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12%;
  cursor: pointer;
}

.check_in p,
.referral p,
.prescription p,
.paid p,
.report p,
.history p,
.check_out p {
  margin: 10px 0;
  color: #000000;
}

.referral_doctor,
.referral_lab {
  margin: 10px 0;
}

.appoinment_icon,
.Profoile_Type {
  height: 40px;
  width: 40px;
}

.dropdown_control_doctor {
  margin: 10px 0;
}

.dropdowncheckbox {
  margin: 16px 0 0 0;
  accent-color: var(--app-theme);
}

.dropdown_control {
  margin: 10px 0 !important;
}

.dropdownimg {
  height: 35px;
  width: 35px;
  margin: 5px;
  border-radius: 50%;
}

.dropdowntext {
  margin: 10px 0 0 0;
}

.files_head {
  margin-top: 50px;
}

.profiles {
  display: flex;
}

.file_upload_content {
  margin: 10px 0;
}

/* ============= Model Style ==================== */

.dropdown_placeholder {
  color: #707070 !important;
  margin: 0;
}

.dropdown_card {
  margin: 0;
  width: 100% !important;
  background-color: #f8f8f8 !important;
  height: 45px;
}

.payment_check,
.payment_check:hover {
  margin-top: 5px;
  width: 15px;
  height: 15px;
  accent-color: var(--app-theme);
}

/* .payment_check:hover {
    width: 15px;
    height: 15px;
    accent-color: #659a15;
} */

/* ========= File Upload referral style start ================= */
.image-container {
  display: inline-block;
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  box-shadow: #00000029 0px 3px 6px;
}

.image-container img {
  margin-top: 20%;
  width: auto;
  height: auto;
  object-fit: cover;
}

.upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* ========= File Upload referral style End ================= */

.success_button {
  text-transform: capitalize !important;
}

.approval_modal_title_walk_booking {
  color: var(--app-theme);
}

.holder_input_name {
  border: none;
  border-bottom: 1px solid #707070;
  margin: 10px 0;
  padding-bottom: 10px;
  outline: none;
  width: -webkit-fill-available;
}

.modal_slots_time {
  color: var(--app-theme);
  /* margin: 12px 0; */
  padding-left: 0;
}

.walkin_total_slots_top {
  margin-top: 0px;
}

.select_finder {
  display: flex;
  justify-content: space-around;
}

.check_box_booked {
  height: 15px;
  width: 15px;
  background-color: #fbeae5;
  border: 1px solid #ff0068;
  margin: 5px;
}

.booked {
  color: #ff0068;
}

.check_box_available {
  height: 15px;
  width: 15px;
  background-color: #510f3040;
  border: 1px solid var(--app-theme);
  margin: 5px;
}

.check_box_selected {
  height: 15px;
  width: 15px;
  background-color: var(--app-theme);
  border: 1px solid var(--app-theme);
  margin: 5px;
}

.selected,
.available {
  color: var(--app-theme);
}

/* ================= */

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 6px 6px 6px 15px !important;
}

/* ================ */

.progressBar {
  width: "100%";
}

/* ================ */
/* 
.dropdown_button {
    width: 210px;
} */

.header_row {
  /* height: 70px */
  height: 80px;
}

.qr_code_img {
  width: 75%;
}

.qr_referral_code {
  color: var(--app-theme);
}

.appoinment_pay_status .MuiGrid-container {
  border-bottom: 1px solid gray;
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
}

.apponment_payment_cost .MuiInputBase-input,
.apponment_payment_cost .MuiTextField-root,
.payment_method_model .MuiInputBase-input,
.payment_method_model .MuiTextField-root {
  width: 100% !important;
}

.appoinment_pay_submit {
  all: unset;
  width: 100px;
  height: 35px;
  background: var(--app-theme);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 15px 0px;
  border-radius: 65px;
}

.payment_submit_appoinment {
  display: flex;
  justify-content: center;
}

.payment_type_title {
  color: var(--app-theme);
  font-size: 16px;
}

.payment_type_title_select {
  color: var(--app-theme);
  font-size: 16px;
  padding-top: 10px;
}

.currency_value {
  color: var(--app-theme);
  font-size: 10px;
}

.appoinment_payment_method {
  display: flex;
  padding: 10px 20px;
  background-color: var(--app-theme);
}

.appoinment_payment_method .appoinment_payment_title {
  color: #fff;
  font-size: 17px;
  font-family: TitilliumWeb-Regular;
}

.appoinment_payment_method img {
  width: 15px;
  cursor: pointer;
}

.payment_method_model .MuiDialogContent-root {
  padding: 0px !important;
}

.paid_through_insurance .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  width: 360px;
}

.paid_through_insurance {
  padding: 10px 20px;
}

.appRef {
  font-weight: bold;
  margin-top: 20px;
}

.pay_label {
  padding-top: 8px !important;
}

/* ========= */
.multiselect_lab_test {
  display: flex;
  justify-content: space-evenly;
}

.css-k5yqkz {
  padding: 0px !important;
}

.css-1qdcfkn {
  padding: 0px !important;
}

.total_count_shows {
  position: absolute;
  top: 13vh;
  width: 100%;
  z-index: 1;
}

/* .fieldtext .MuiInputBase-input .MuiOutlinedInput-input .MuiInputBase-inputMultiline .css-u36398 {
    margin: -5px 0px;
} */

.fieldtext {
  margin: -5px 0px;
}

.border_curvesize {
  border-radius: 20px 0px 0px 20px;
}

.border_curvesizes {
  border-radius: 0px 20px 20px 0px;
}

.paymentMOdelLen {
  .appoinment_payment_method {
    position: sticky;
    top: 0;
  }

  .css-ypiqx9-MuiDialogContent-root {
    height: 55vh;
    overflow-y: scroll;
  }
}

.error-message {
  color: #ff0068;
  font-size: 12px;
}

.field_required {
  color: #ff0068;
  font-family: "Roboto-Regular";
  padding-top: 10px !important;
  text-align: left;
  font-size: 12px;
}

.files_heads {
  margin-top: 40px;
}

.page_head_date_container,
.page_head_doctor_container {
  display: flex;
  align-items: center;
}

.dropdown-doctormenu {
  height: 400px;
}
.dropdown-labmenu {
  height: 400px;
}

@media (min-width: 992px) and (max-width: 1100px) {
  .check_in p,
  .referral p,
  .prescription p,
  .paid p,
  .report p,
  .history p,
  .check_out p {
    margin: 10px 0;
    color: #000000;
    font-size: 13px;
  }
}

.add_additional_service {
  height: 26px;
  width: 31px;
}

._f10 {
  font-size: 10px;
}

.payment_method_model {
  min-height: 28vh;
  max-height: 75vh;
  overflow-y: auto;

  .MuiSvgIcon-root {
    color: #510f30 !important;
  }
}

.additional_payment_source {
  .MuiSvgIcon-root {
    color: #510f30 !important;
  }
}

.rm-border-bottom {
  border: none !important;
  padding: 0 !important;
}
.payment_method_model_rm_insu {
  max-height: 12vh;
  display: flex;
  align-items: center;
  overflow-y: hidden;
}

.appointment_details_list {
  width: 53%;
  padding-left: 81px;
}
.date_change_appointement {
  width: 56vh;
  border: 1px solid #2f887c;
  background-color: #2f887c;
  color: white;
  border-radius: 12px;
}
.date_design_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.appointment_2list {
  border: 1px solid #2f887c;
  background-color: #2f887c;
  border-radius: 10px;
}
.page_head_date_container {
  padding: 0;
  padding-right: 75px !important;
}
.profile_member_image {
  height: 31px;
  width: 30px;
}
.particular_card_appointment_details {
  border-radius: 13px;
  padding: 22px;
}
.header_profile_details {
  border: 1px solid #2f887c47;
  border-radius: 13px;
  padding: 15px;
  display: flex;
  margin-bottom: 40px;
  box-shadow: 0px 0px 6px -3px;
}

.profile_Details1 {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 20px;
  box-shadow: #00000029 0px 3px 6px;
}
.detailsprofile1 {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.profile_details_prescription_new {
  border-bottom: 0 !important;
  display: flow !important;
  padding-bottom: 0 !important;
}
.content_view_appointment {
  /* margin-top: 29vh !important; */
}
.appointment_stepper_details {
  width: 95px !important;
}
.appoint_horizontal_stepper_details {
  padding-top: 24px !important;
}
.time_appointments_details {
  font-size: 15px !important;
  color: black;
  text-align: start;
  font-weight: 600 !important;
}
.today_count,
p {
  margin-bottom: 12px;
}
.Navication-header {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  background-color: #31897d33;
  margin: 1rem 0rem 1.6rem 0rem;
  position: absolute;
  width: 100%;
  top: 11vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.appointment_page_header {
  padding: 0px 83px 0px 0px !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  padding: 0px 30px !important;
}
@media only screen and (max-width: 1600px) {
  .appointment_stepper_details {
    width: 70px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .appointment_stepper_details {
    width: 58px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .appointment_stepper_details {
    width: 50px !important;
  }
  .time_appointments_details {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 1150px) {
  .appointment_stepper_details {
    width: 22px !important;
  }
  .time_appointments_details {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .appointment_stepper_details {
    width: 10px !important;
  }
  .time_appointments_details {
    font-size: 9px !important;
  }
}
.appointment_page_add_icon {
  height: 21px !important;
  width: 40px !important;
}
.appointment_page_image_icon_details img {
  padding: 0rem 0.5rem 0rem 0.5rem !important;
}
