.card_content_one {
  margin-top: 20px;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.drag_drop_img {
  margin-top: 8px;
  background: var(--app-theme);
  margin-left: -5px;
}

.tit_cls {
  margin-left: 4px;
}

.success_post_add_btn {
  background: var(--app-theme) !important;
  border-radius: 23px !important;
  margin: 10px 0px !important;
  color: #fff;
  padding: 8px 30px !important;
  text-transform: capitalize !important;
}

.success_post_add_btnupload {
  background: #D4C3CC !important;
  border-radius: 23px !important;
  margin: 10px 0px !important;
  color: #510F30 !important;
  padding: 8px 30px !important;
  text-transform: capitalize !important;
}

.header_right_content .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 195px !important;
}

.popup22 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 20px 20px 20px 20px;
}

.view_post_cls {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
}

.input_field_services {
  width: 100% !important;
  margin-bottom: 15px !important;
}

.input_field_services input {
  height: 10px !important;
  color: #707070 !important;
}

.content_row_post {
  padding: 5px;
}


.post_content {
  padding-top: 15px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit_icon_post {
  background-color: var(--app-theme) !important;
  color: #fff !important;
  border-radius: 40px !important;
  margin-right: 8px !important;
  box-shadow: 0px 0px 9px #0000004D !important;
  text-transform: capitalize !important;
  font-size: 14px;
  width: 50px;
  height: 30px;
}

.delete_icon_post {
  background-color: #FF0068 !important;
  color: #fff !important;
  padding: 5px 15px !important;
  font-size: 14px;
  border-radius: 40px !important;
  box-shadow: 0px 0px 9px #0000004D !important;
  text-transform: capitalize !important;
  width: 50px;
  height: 30px;
}

.card_row_img_post {
  height: 50px;
  width: 50px;
  object-fit: cover;
  cursor: pointer;
}

.card_content_one .post_edit_del_btn {
  display: block !important;
  padding-top: 10px;
  gap: 0px;
  text-align: center;
}

.upload_add_btn {
  text-align: center !important;
}

.input_file {
  position: relative;
}

.post_edit_del_btn {
  display: flex;
  padding-top: 10px;
  justify-content: center;
  gap: 10px;
}

.post_active_status {
  text-align: center;
  padding-top: 13px;
}

.post_active {
  font-size: 15px;
  margin-left: 10px;
}

.post_active_status .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 !important;
}

.post_active_status .MuiSvgIcon-fontSizeMedium {
  color: var(--app-theme) !important;
  border: #CCCCCC !important;
}

.file_alert_icon {
  height: 14px;
  margin-left: 10px;
  margin-top: -2px;
  cursor: pointer;
}

.upload_browser_file {
  background-color: var(--app-theme) !important;
  padding: 11px 20px !important;
  font-size: 11px !important;
  position: absolute !important;
  top: 30px !important;
  right: 0 !important;
  cursor: pointer;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
}

.post_toggle {
  display: flex;
}

.every_day {
  margin-left: 5px !important;
  margin-top: 2px !important;
}

/* alert model design start */
.alert_btn_center {
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

.alert_no_btn {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: #000 !important;
  border-radius: 23px !important;
  background-color: #70707080 !important;
  padding: 5px 28px !important;
  margin-right: 10px !important;
  outline: none;
  border: none;
}

.alert_yes_btn {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  color: #fff !important;
  border-radius: 23px !important;
  padding: 5px 28px !important;
  margin-left: 10px !important;
  background-color: var(--app-theme) !important;
  outline: none;
  border: none;
}

.image_content_manage_servive {
  height: 100%;
  width: 100%;
  object-fit: cover;

}

/* alert model design end */
.success_title {
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 10px;
}

.uploadins_head {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin-bottom: 0px;
  font-family: TitilliumWeb-Regular;

}

.upload_instruction_full {
  background-color: #510F30;
  box-shadow: 0 0 3.5px 0 #ccc;
  border-bottom: 1px solid #ccc;
  /* height: 47px; */
  display: flex;
  align-items: center;
  padding: 20px !important;
  justify-content: space-between;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding: 0px !important;
}

.progressBar .MuiLinearProgress-bar {
  color: #510F30 !important;
  background-color: #510F30 !important;
  width: 100% !important;
}

.progressBar .MuiLinearProgress-root {
  color: #510F30 !important;
  background-color: #510F30 !important;
  width: 100% !important;
}

.upltime {
  margin-left: 33px;
}

.upldate {
  margin-left: 40px;
}

.post_title_header label {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.image_body_sizes {
  height: 80px;
  width: 80px;
}

.error_post {
  text-align: left;
  margin-top: -8px;
  color: #ff0068;
  font-size: 12px;
  font-weight: bolder;
}

.success_model_body .failed {
  color: #ff0068;
  font-family: 'TitilliumWeb-Regular' !important;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}


@media (min-width: 1200px) {
  .card_content_one .media_title {
    max-width: 22% !important;
  }


  .post_title_header .status_title {
   text-align: center !important;
  }

  .content_row_post .post_edit_del_btn {
    display: block;
    padding-top: 10px;
    text-align: center !important;
  }
  .content_row_post .post_active_status {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: start;
    padding-left: 0px !important;
  }
  .post_title_header .status_title {
    text-align: center !important;
  }

  .post_active {
    font-size: 15px !important;
  }
}

@media (min-width: 900px) {
  .card_content_one .media_title {
    max-width: 21%;
  }

  .content_row_post .post_edit_del_btn {
    display: block;
    padding-top: 10px;
    text-align: end;
  }

  .content_row_post .post_active_status {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: start;
    padding-left: 25px;
  }

  .post_title_header .status_title {
    text-align: justify !important;
  }

  .post_active {
    font-size: 13px !important;
  }
}

@media (min-width: 600px) {
  .card_content_one .media_title {
    max-width: 21%;
  }
  .content_row_post .post_active_status {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding-left: 10px;
  }
  .content_row_post .post_edit_del_btn {
    display: block;
    padding-top: 10px;
    text-align: end;
  }

  .post_title_header .status_title {
    text-align: center !important;
  }

  .post_active {
    font-size: 14px !important;
  }
}

.page_head_doctor_container{
  display: flex;
  align-items: center;
  margin: 0 0.3rem;
}
.css-1hw9j7s.Mui-disabled {
  color: white !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  color: white !important;
}
.thumbnil{
  text-align: center !important;
}