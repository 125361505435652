.crdcls {
    flex: 0 0 calc(16.66% - 17px)  !important;
    display: flex;
    align-items: center;
    justify-content: center; 
}

.Crdhead {
    color: white !important;
    display: flex;
    justify-content: center;
    gap: 4px;
    font-weight: 500 !important;
    /* height: 30px; */
    align-items: center;
    font-family: 'Roboto-Regular' !important;

}
.card_body{
    padding: 5px 10px 10px;
}
.card-content{
    min-height: 36px;
    align-items: center;
    justify-content: space-around;
    display: flex;
    margin-bottom: 0px !important;
    margin-top: 5px;
}
.supLetter {
    font-size: 14px !important;
    text-align: center;
    font-weight: 600;
}
.titleLetter{
    font-size: 16px !important;
    text-align: center;
    font-weight: 600;
}
.titleLetters{
    font-size: 14px;
    text-align: center;
    color: #000;
    font-weight: 600;
    line-height: 16px;
}
.titleLetter1{
    font-size: 16px !important;
    text-align: center;
    font-weight: 600;
}
.titleReferral{
    font-size: 15px !important;
    text-align: center;
    font-weight: 600;
}
.supLetter span{
    font-size: 12px;
}
.supLetter img{
    padding-left: 2px;
}
.selectIcons{
    width: 18px;
    /* margin-left: 5px; */
}
.Secondary_font {
    color: #fff;
    /* border-bottom: 1px solid #fff; */
    display: flex;
    justify-content: end;
}

.Crdhead1 {
    color: #510f30 !important;
    display: flex;
    justify-content: center;
    font-weight: 500 !important;
    height: 30px;
    gap: 4px;
    align-items: center;
    font-family: 'Roboto-Regular' !important;

}
.Crdhead11 {
    color: #510f30 !important;
    display: flex;
    justify-content: center;
    font-weight: 500 !important;
    height: 30px;
    align-items: center;
    font-family: 'Roboto-Regular' !important;

}

.Secondary_font1 {
    color: #510f30;
    /* border-bottom: 1px solid #510f30; */
    display: flex;
    justify-content: center;
}

.Paidcrd {
    cursor: pointer;
    height: 110px;
    background-color:#510f30 !important;
    display: flex;
    align-items: center;
}
.Paidcrd1 {
    cursor: pointer;
    height: 110px;
    /* background-color:#510f30 !important; */
}

.Fastcrd {
    cursor: pointer;
    height: 110px;
    background-color: #f8e0d8 !important;
    display: flex;
    align-items: center;
}

.Walkcrd {
    cursor: pointer;
    height: 110px;
    background-color: #ff0068 !important;
    display: flex;
    align-items: center;
}

.Insurancecrd {
    cursor: pointer;
    height: 110px;
    background-color:#00a49a !important;
    display: flex;
    align-items: center;
}

.FullCoveragecrd {
    cursor: pointer;
    height: 110px;
    background-color: #f49c7a !important;
    display: flex;
    align-items: center;
}

.partailcrd {
    cursor: pointer;
    height: 110px;
    background-color: #00a49a !important;
    display: flex;
    align-items: center;
}

.Reffcrd {
    cursor: pointer;
    height: 110px;
    background-color:#ff0068 !important;
    display: flex;
    align-items: center;
}

.Revenuecrd {
    cursor: pointer;
    height: 110px;
    background-color:#00e8d2 !important;
    display: flex;
    align-items: center;
}

.Tomsharecrd {
    height: 110px;
    background-color:#f49c7a !important;
    display: flex;
    align-items: center;
}

.Deductioncrd {
    cursor: pointer;
    height: 110px;
    background-color:#00a49a !important;
    display: flex;
    align-items: center;
} 

.shadow_Class {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.overlay_Class {
    opacity: 0.2 !important;
}
.Icons{
   height: 18px;
}
.Amount_value{
    padding: 0px 6px;
    font-size: 12px;
    margin-top: 2px;
}
.num_crd{
    width: 15%;
}
.crd_content{
    padding: 0px !important;
    width: 100%;
}
.number_crd{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(16.66% - 17px);
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 130px;
}
.number_font{
    font-size: 9px;
}

@media (min-width: 992px) and (max-width: 1100px) {
   
    .supLetter {
        font-size: 10px !important;
    }
    .supLetter span {
        font-size: 9px;
    }
    .titleLetter{
        font-size: 14px !important;
    }
    .titleLetter1{
        font-size: 12px !important;
    }
    .titleLetters{
        font-size: 14px !important;
        color: #000;
    }
    .titleReferral{
        font-size: 10px !important;
        color: #FFF;
    }
    .number_font{
        font-size: 8px;
    }
}