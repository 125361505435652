.profile_pic {
  height: 70vh;
  width: 100%;
  border-radius: 5px;
}

.profile_pic_container {
  padding: 25px 30px;
  position: relative;
}

.profile_pic_container span {
  position: absolute;
  font-size: 55px;
  color: var(--app-theme);
  right: 40px;
  top: 17px;
}
.profile_pic_new {
  height: 100px;
  width: 100px;
  border-radius: 110px;
}

.profile_upload {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 23px;
  width: 100px;
  height: 100px;
  background-color: aquamarine;
  border-radius: 50px;
  bottom: 0;
  top: 0.5vh;
  right: 1vh;
  /* top: 31.5vh;
  left: 97.7vh; */
}

.profile_edit {
  color: var(--app-theme);
  padding-top: 17px;
  padding-right: 10px;
}

.profile_fields_container {
  padding: 60px 30px;
}

.profile_since {
  display: flex;
}

.profile_doctor_name {
  font-weight: bold;
  color: #510f30;
  /* padding: 0px 0px 30px 0px; */
  font-size: 28px;
}

.profile_doctor_history {
  font-weight: bold !important;
  padding-top: 10px !important;
  margin-right: 10px !important;
  color: #f49c7a !important;
}

.profile_input_since {
  padding-top: 5px !important;
  width: 250px !important;
  color: #f49c7a !important;
}

.input_label {
  color: #510f30 !important;
  padding-top: 5px !important;
  font-size: 24px !important;
  font-family: "Roboto-Regular" !important;
}

.profile_input {
  padding: 3px 0px 0px 0px !important;
  width: 250px !important;
}

.profile_input ::before {
  border-bottom: 0 !important;
}

.profile_input ::after {
  border-bottom: 0 !important;
}

.profile_fields_container .css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border: none !important;
  color: #411edf !important;
}

.profile_fields_container .css-v4u5dn-MuiInputBase-root-MuiInput-root::after {
  border: none !important;
  color: #411edf !important;
}

.profile_sub_header {
  display: flex;
  justify-content: center;
}

.profile_sub_img {
  margin: 8px 15px 0px 0px;
  height: 23px;
}

.profile_fields_container .css-v4u5dn-MuiInputBase-root-MuiInput-root {
  font-size: 18px !important;
}

.logout_btn {
  margin-top: 50px !important;
  background: var(--app-theme) !important;
  padding: 3px 20px !important;
  border-radius: 20px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

.butn_div {
  text-align: end;
  margin-right: 5%;
}
.vendor_name_details {
  /* padding-top: 70px !important; */
  display: flex;
  align-items: center;
}
.logout_details {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 8px;
}
.save_profile_details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px;
}
.speciality_name_details {
  color: var(--app-theme);
  margin-bottom: 10px;
}
.star_details {
  color: #ff0000;
}
.textfield_details {
  height: 40px !important;
  margin: 0 !important;
  width: 34vh !important;
  min-width: 200px !important;
}
.coloumn_2details {
  /* margin-left: 10%; */
  padding-bottom: 20px;
  /* padding-top: 76px; */
}
.coloumn_1details {
  padding-bottom: 35px;
  /* padding-top: 76px; */
}
.MuiOutlinedInput-notchedOutline {
  border-color: var(--app-theme) !important;
  box-shadow: 0px 0px 6px -3px;
}
.speciality_email_details {
  margin-top: 10px;
  justify-content: space-around;
}
.save_details_new {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.save_button_details {
  border: 1px solid red;
  padding: 6px 33px 6px 33px;
  border-radius: 25px;
  border-color: var(--app-theme);
  background-color: var(--app-theme);
  color: white;
  font-size: 16px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}
.save_button_details:hover {
  transform: scale(1.05);
  background: #3da394;
}
.logout_button_details {
  border: 1px solid red;
  padding: 6px 24px 6px 24px;
  border-radius: 25px;
  border-color: var(--app-theme);
  background-color: var(--app-theme);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.logout_button_details:hover {
  transform: scale(1.05);
  background: #3da394;
}
.profile_doctor_name_new {
  font-weight: bold;
  color: var(--app-theme);
  /* padding: 0px 0px 30px 0px; */
  font-size: 20px;
  margin: 0;
}
.profile_border_details {
  border: 1px solid var(--app-theme);
  /* padding: 15px; */
  border-radius: 25px;
  box-shadow: 0px 0px 6px -3px;
  width: 100vh !important;
  display: flex !important;
  justify-content: end;
}
.Pharmacy_select_profile {
  height: 40px !important;
  width: 34vh;
}
.css-k1aq4d {
  height: 45px !important;
}

.profile_upload_select {
  font-size: 23px;
  height: 22px;
  width: 22px;
  position: relative;
  top: 30px;
  right: 12px;
  cursor: pointer;
}
.img_contain {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 15px !important;
}
.profile_upload_added {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
