.profile_details_prescription {
  border-bottom: 3px solid var(--app-theme);
  display: flex;
  padding-bottom: 62px;
}

.profile_detail_container {
  display: flex;
  /* padding: 0px 30px 0px 30px; */
}

.profile_Details {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 0px 20px 0px 0px;
  box-shadow: #00000029 0px 3px 6px;
}

.detailsprofile {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.profile_name,
.profile_time {
  margin: 0px;
}

.profile_time {
  color: #f49c7a;
  text-transform: lowercase;
}

.profile_age,
.profile_number {
  font-size: 12px;
  color: #f49c7a;
  margin: 3px 0;
}

.profile_detail_type {
  text-align: end;
  margin-top: 25px;
}

.prescription_contnt {
  margin-top: 50px;
}

.alert_image {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  /* box-shadow: #00000029 0px 3px 6px; */
  margin: 10px;
}

.note {
  color: var(--app-theme);
  margin: 15px;
}

.alert_text {
  color: #f49c7a;
}

.report_image,
.send_button,
.cancel_button {
  margin: 0 !important;
}

.button_container {
  margin: 25px;
}
.save_details_new_report {
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 0px;
}

.report_cancel_button {
  height: 3px;
}

.button_text {
  width: fit-content;
  border-radius: 25px;
  border: 1px solid #707070;
  cursor: pointer;
}

.button_text p,
.button_text p span {
  font-size: 10px;
  margin: 3px 5px;
  /* width: 195px; */
  display: flex;
}

.report_cancel_button span {
  color: red;
}

.presription_image {
  width: 100px;
  display: flex;
}

.prescription_image_align {
  display: flex;
  justify-content: center;
  /* align-items:cejnte */
}

/* ==================== */
.botton_Send_Pharmacy {
  color: #ffffff !important;
  border-radius: 25px !important;
  background-color: var(--app-theme) !important;
  padding: 10px 20px;
  background: var(--app-theme) 0% 0% no-repeat padding-box;
  border: 1px solid var(--app-theme);
  /* width: 100px; */
  margin-top: 40px;
}

.Total_prescription_details {
  border: 1px solid var(--app-theme);
  width: 33%;
  margin: 39px 0px 0px 29px !important;
  height: 175px;
  border-radius: 27px;
  box-shadow: 0px 0px 6px -3px;
}

.prescription_contain_details {
  text-align: center;
  padding-top: 21px;
  /* padding-right: 30px; */
}
.Total_number_details {
  text-align: end;
  color: var(--app-theme);
}
.total_medicine_details {
  padding: 15px;
}
.prescription_details_start {
  text-align: start;
  color: var(--app-theme);
  font-size: 18px;
  font-weight: 600;
  padding: 0px 26px 0px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.medicine_name_details {
  color: var(--app-theme);
}
.precription_stepper_line {
  background-color: var(--app-theme);
}
.save_details_new_prescription {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 112px 75px 40px 40px;
}
.prescription_add_plus {
  border: 1px solid var(--app-theme);
  border-radius: 15px;
  width: 70px;
  background-color: var(--app-theme);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.prescription_add_plus:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}

.plus_icon_prescription {
  display: flex;
  justify-content: center;
  align-items: center;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 11px !important;
  height: 53px;
}
.prescription_plus {
  margin: 0;
  height: 40px !important;
}
.morning_medicine_details {
  width: 74vh !important;
  padding-top: 40px;
}

.particular_days_details {
  display: flex;
  justify-content: end;
  align-items: end;
  color: var(--app-theme);
}
.particular_days_details_medicine_count {
  display: flex;
  justify-content: end;
  align-items: end;
}
.medicine_details {
  display: flex;
  justify-content: center;
  align-items: center;
}
.medicine_total_details {
  border: 1px solid var(--app-theme);
  padding: 1px 7px 1px 11px;
  border-radius: 12px;
  background-color: #a0d6cf;
}
.medicine_prescription_data {
  border: 1px solid var(--app-theme);
  padding: 7px 10px 7px 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px -3px;
}
.success_model_body {
  padding-top: 0vh;
  padding-bottom: 12px;
}
.total_medicine_count {
  border: 1px solid var(--app-theme);
  width: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 11px;
  box-shadow: 0px 0px 6px -3px;
  margin-top: 22px;
  font-size: 18px;
  color: var(--app-theme);
  font-weight: 600;
  height: 41px;
}
.prescription_list_details {
  display: flex;
  gap: 34px;
}
.remove_medicine_details {
  cursor: pointer;
  /* margin-left: 15px; */
  color: red;
  border: 1px solid red;
  border-radius: 11px;
  /* padding: 0px 2px 0px 2px; */
  font-size: 13px;
  background-color: white;
  height: 20px;
  width: 20px;
  justify-content: center;
  display: flex;
}
.css-16ytuc0 .MuiDialog-container .MuiPaper-root {
  width: 100%;
  max-width: 94vh !important;
  border-radius: 20px;
  height: 68vh;
}
/* .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
    padding-top: 15px !important;
    padding-bottom: 15px !important;
} */
.MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 13px !important;
}
.morning_medicine_count_detail {
  width: 74vh !important;
  padding-top: 20px;
  padding-bottom: 12px;
}
.css-18nc3u2 .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 6px;
}   

.label_static_alignment {
  position: absolute;
  top: 0;
  width: 94%;
  z-index: 20;
}

.scrollable_container {
  margin-top: 20vh; /* Adjust based on header height */
  overflow-y: auto; /* Enables scrolling for the container */
  max-height: 375px; /* Adjust height as necessary */
}
.prescription_close_mark_details {
  padding-top: 18px;
}
.prescription_page_new {
  padding: 6px 74px 4px 40px;
}
.medicine_details_new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--app-theme);
  padding: 5px;
  border-radius: 5px;
  background-color: #a0d6cf;
  font-size: 14px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.medicine_details_new:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}
.prescription_namee_details {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.close_mark_contain_pre{
  display: flex;
  justify-content: end;
}