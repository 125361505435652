.toggle234 {
    padding: 10px;
    margin: 0px !important;
    cursor: pointer;
    color: #777777;
    width: 140px;
    display: flex;
    justify-content: center;
    height: 35px;
    align-items: center;
}
 .Scrool_bar_dash{ 
  /* overflow-y: auto !important; */
  /* overflow-x: auto !important; */
  overflow: hidden;
 } 
.toggleActive {
    padding: 10px;
    margin: 0px !important;
    cursor: pointer;
    background-color: #510f30;
    color: #fff;
    border-radius: 18px;
    width: 140px;
    display: flex;
    justify-content: center;
    height: 35px;
    align-items: center;
}
.whloetoggle {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    margin-right: 20px;
}
.overview {
    padding: 10px;
    margin: 0px !important;
    cursor: pointer;
    color: #777777;
}
.overviewbox {
    margin: 0px !important;
    cursor: pointer;
    height: 25px;
    width: 25px;
    background-color: #ff0068;
    cursor: pointer;
    margin-right: 10px;
}
.RSMember_data{
    /* width: 70px; */
    text-align: start;
    /* margin-left: 30px; */
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.settlementClassRS{
  width: 71px;
  display: flex;
  justify-content:end ;
}
.chikimuki .MuiSvgIcon-fontSizeMedium{
    color: #510f30 !important;
    font-size: 20px !important;
}


  .check_selectAll{
    padding: 0px 23px;
    font-size: 13px;
    display: flex;
    color: #777777;
    align-items: center;
  }
  .Total_income {
    background-color: #ff0068;
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    position: relative;
    /* bottom: 120px; */
    width: auto;
    border: 0;
  }
  .revice_button{
    background-color: #510f30;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    position: relative;
    bottom: 120px;
    width: auto;
  }
  .revice_buttohhn{
    display: none !important;
  }
  .Active_Icon{
    height: 35px;
    cursor: pointer;
  }
  
  /* new flow css */
  .RsviewMore{
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: relative;
  }
  .Rspaybtn{
    /* padding: 5px 25px;
    border-radius: 17px;
    font-size: 11px; */
    padding: 5px 0px;
    width: 74px;
    border-radius: 17px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .RSpaid{
    background-color: #510f30;
    color: #fff;
  }
  .RSnotpaid{
    background-color: #ff0068;
    color: #fff;
  }
  .RSnotpaidText{
    color: #ff0068;
  }
  .RSpaidText{color: #510f30;}
  .RspaybtnText{
    /* position: absolute;
    left: 57%;
    top: -67%; */
    font-size: 11px;
    font-weight: 600;
    /* white-space: nowrap; */
  }

  .RSsettlePaid{
    justify-content: space-between;
    width: 60%;
    align-items: center;
    margin: 0 auto;
    position: relative;
  }
  
   /* model upload */

   .rs_model_title {
    background-color: #510f30;
    color: #fff;
    text-transform: uppercase;
    border-radius: 0px 0px;
    display: flex;
    width: 100%;
    /* height: 50px; */
    font-size: 18px;
    font-family: 'TitilliumWeb-Regular';
    border-radius: 20px 20px 0px 0px;
    padding: 10px;
    justify-content: space-between;
}

.rs_close_icon {
    color: #ff0068;
    font-size: 24px!important;
    cursor: pointer;
}

.rs_Model_crd {
    border-radius: 10px;
}

.rs_recpit_crd {
    height: 200px;
    width: 250px;
    border-radius: 5px;
    background-color: #b2afb0;
}

.rs_Downloadimg {
    cursor: pointer;
}

.rs_segment_line {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 25px;
    cursor: pointer;
    padding: 0px;
    margin: 0 10px;
    width: 30%;
}

.rs_active {
    border-bottom: 2px solid #510f30;
    color: #510f30;
}
.rs_frame{
  width: 250px;
  border: 5px solid #70707073;
}
.model_title1 {
  background-color: #510F30;
  color: white;
  padding: 10px 21px;
  border-radius: 20px 20px 0 0;
  
  }
  .font_9{
    font-size: 9px;
}

.rsframe{
  width: 300px;
    padding: 20px 15px;
    background: hsl(332.31deg 13.4% 80.98%);
    border: 1px dashed #510f30;
    display: flex;
    margin: 10px auto 0 auto;
    border-radius: 9px;
}

.allkwd_lbl{
  text-align: end;
  margin-bottom: 6px;
  font-size: 14px;
  color: rgb(255, 0, 104);
}
.header_value {
  font-size: 20px;
  text-transform: uppercase;
  color: #510f30;
  align-items: center;
  cursor: context-menu;
  font-family: 'TitilliumWeb-Regular';

}