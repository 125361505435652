.Select_Label_work {
  padding: 10px 0;
  width: 50vh !important;
}

.worckselectors {
  /* padding: 15px;
    margin: 0 10px;
    width: 270px !important; */
}

/* .select_option {
    margin: 0 0 0 28px !important;
} */

.slotcounts {
  /* background-color: aquamarine; */
  margin-top: 50px;
  font-size: 17px !important;
  font-family: Roboto-Regular !important;
}

.slotcounts p {
  color: var(--app-theme);
}

.slotcounts span {
  /* color: black; */
  color: #707070;
}

.Everyday {
  /* color: #666666 !important; */
  color: #707070 !important;
  margin-left: 20px !important;
  font-size: 17px !important;
  font-family: Roboto-Regular !important;
}

.selectEverytog {
  display: flex !important;
  /* margin-left: 15px; */
  padding-top: 1rem;
}

.toggle {
  margin: -18px 0 0 0px !important;
}

/* .selectEverytog p {
    font-weight: bold;
} */

.detail_list {
  margin: 0 100px;
  padding: 10px 0;
  box-shadow: #64646467 0px 0px 12px !important;
  margin-top: 20px;
}

.DayButsad {
  margin: 20px 100px;
}

.singleDay {
  display: flex;
}

.TotalsingleDay {
  display: flex;
  /* justify-content: space-evenly;
    margin: 0 95px; */
  margin-left: 53px;
}

.botton_Cancel {
  height: 30px;
  width: 75px;
  font-size: 14px !important;
  padding: 0 !important;
  margin: 5px !important;
  background-color: #e12f2f !important;
}

.Action {
  /* color: #000000; */
  color: #510f30;
  margin-bottom: 0;
}

.Action_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.Detailtypes {
  display: flex;
  justify-content: space-around;
}

.Detailcont {
  width: 240px;
}

.specialtyimgRow {
  margin: 0;
  width: 100%;
}

.specialtyimg {
  height: 25px;
  width: 18px;
  margin-left: 6px;
}

.specialtyimgsesond {
  height: 25px;
  width: 18px;
  margin-left: 6px;
  margin-right: 10px;
}

.Column_ {
  padding: 0;
}

.Column_1 {
  padding: 0;
  position: relative;
  margin-top: -22px !important;
}

.fa-clock-o {
  margin-top: 3px;
  font-size: 25px;
}

.Select_Label {
  border: 1px solid var(--app-theme);
  border-radius: 4px;
  padding: 4px 0px;
  margin: 10px 0 10px 0 !important;
  /* width: 250px !important; */
  height: 40px;
}
.slots_count {
  margin: 50px 0 0 0 !important;
}

.headlabel {
  font-size: 17px !important;
  color: var(--app-sub-header);
  font-family: Roboto-Regular !important;
  margin-left: 5px;
}

.labtext {
  font-size: 17px !important;
  color: #000000 !important;
  font-family: Roboto-Regular !important;
  margin-top: 5px !important;
  margin-left: 10px !important;
}

.days_text {
  font-size: 17px !important;
  color: #666666 !important;
  margin-right: 20px;
  font-family: Roboto-Regular !important;
}

.small_txet {
  font-size: 14px !important;
  /* color: #000000 !important; */
  color: #510f30 !important;
  font-family: Roboto-Regular !important;
  margin-bottom: 0;
}

.text_lab {
  font-size: 14px !important;
  color: #707070 !important;
  font-family: Roboto-Regular !important;
  margin-bottom: 5px;
}

.tymbox {
  border-radius: 4px;
  margin: 15px 0 0 0;
  border: solid 1px #2f887c;
  height: 44px;
  padding: 15px;
  width: 50vh;
  text-transform: lowercase;
}

.headerlab {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 8px;
  margin-right: 18px;
}

.headerlabs {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1px;
}

.workinghours_content {
  height: 76vh;
  margin-top: 24vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
}

.header_right_contents {
  display: flex;
}

.header_right_contents
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 195px !important;
  /* margin-right: 10px !important; */
}

.Workingview_btn {
  background-color: #510f30;
  color: white;
  padding: 7px 36px;
  border: 1px solid var(--app-theme);
  border-radius: 30px;
  /* margin: 0px 0 0 calc(8vw - 6px); */
  cursor: pointer;
  /* width: 190px; */
}

.select_option
  .css-1rr0u3f-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 40px;
}

.From_Tym {
  margin-left: 8px;
}

.DayButs {
  margin-left: 0.5rem;
}

.image_size {
  margin-left: 5px;
  width: 15px;
}

.dropdown_button .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 41px;
}

/* .selectoption {
    background-color: #fff;
} */

/* input[type="time"] {
    position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
} */
.dropdown_button .css-1v4ccyo {
  height: 41px !important;
}

.slot_clr {
  color: #000000 !important;
  margin-left: 4vh;
}

.splClsH {
  text-align: left;
  padding-inline-start: 2rem;
}

.splClsB {
  text-align: left;
  padding-inline-start: 2rem;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

.botton_Add_hours {
  border: none !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  background-color: var(--app-sub-header) !important;
  text-transform: capitalize !important;
  text-transform: uppercase !important;
  padding: 10px 30px !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.botton_Add_hours:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}

.add-hours-content {
  margin: 0vh 2rem;
  overflow: hidden;
  box-shadow: 0px 3px 6px #2f887c75;
  border: 1px solid #30897c57;
  border-radius: 17px;
  padding: 2rem;
}

.add-dvsion {
  display: flex;
  justify-content: center;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: var(--app-sub-header) !important;
}

.css-1m3cev1-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1m3cev1-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(--app-sub-header) !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(--app-sub-header) !important;
}

.dropdown_button .MuiInputBase-inputSizeSmall,
.MuiSvgIcon-fontSizeMedium {
  color: black !important;
}

.pushNotification_body {
  /* display: flex; */
  position: relative;
  /* top: 24px;
    left: calc(100vw - 70%); */
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 10px;
  width: fit-content;
  /* z-index: 9999999; */
  margin: auto;
  border-radius: 0px 0px 54px 54px;
}

.pushNotification_content {
  padding: 4vh 4vh 1vh 4vh;
  font-size: 25px;
}

.notification_text_head {
  margin: 5px 10px;
  font-size: 25px !important;
  color: var(--app-sub-header);
}

.popup-main {
  position: fixed;
  z-index: 999999;
  height: 100%;
  width: 100%;
  top: -2vh;
  left: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.progress-bar {
  height: 4px;
  width: 100%;
  background-color: #ffffff; /* Light green for background */
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--app-sub-header); /* Darker green for progress */
  animation: progress 3s linear forwards; /* Adjust timing to match duration */
  border-radius: 10px;
}

@keyframes progress {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.progress-prt {
  padding: 0vh 5vh;
}
.Navication-header {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  background-color: #31897d33;
  margin: 1rem 0rem 1.6rem 0rem;
  position: absolute;
  width: 100%;
  top: 11vh;
  /* box-shadow: 0px 3px 6px #2F887C75;
    opacity: 0.6; */
}
.Navication-header label {
  cursor: pointer;
  font-size: 25px;
  color: var(--app-sub-header);
}
.Navication-header i {
  font-size: 25px;
  padding: 0rem 0.5rem;
  color: var(--app-sub-header);
}
.Navication-header img {
  padding: 0rem 0.5rem 0.4rem 0.5rem;
  cursor: pointer;
}
.css-1x5jdmq {
  padding: 10px 14px !important;
}
