.Reporttitle {
  margin: 8px;
  font-weight: bold;
}

.Reporttime {
  font-size: small;
  margin: 12px;
  color: #777777;
}

.Reportimgs {
  height: calc(100vh - 245px);
  width: 100%;
  object-fit: contain;
}

.report_holder {
  width: 100%;
}

.line_one {
  margin: 80px 0 0 70px;
}

.OkButton {
  margin: 15px 0 !important;
}

.report_container {
  display: flex;
}

.comments_container,
.lab_container,
.Prescriptions_container,
.clini_container {
  width: -webkit-fill-available;
  border-bottom: 2px solid #2f887c47;
}

.clini_container {
  border-right: unset !important;
}

.add_comment {
  font-size: 12px;
  color: var(--app-theme);
  margin: 0;
}

.plus_comment {
  font-size: 28px;
  color: var(--app-theme);
  margin: 0;
  cursor: pointer;
}

.add_comment_container {
  width: 80px;
  position: absolute;
  margin: 10px 0px 0px 16px;
}

.report_title {
  font-size: 18px;
  margin-top: 40px;
}

.stepper {
  margin-top: 15px;
}

.stepper_circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #48bfe3;
}

.line {
  width: 0px;
  height: 65px;
  border: 2px solid #48bfe3;
  opacity: 40%;
}

.card_content_one {
  margin-top: 0px;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.prescriptions_detail {
  display: flex;
  justify-content: center;
}

.report_card {
  box-shadow: #00000029 0px 1px 5px;
  width: 150px;
  height: 65px;
  text-align: start;
  padding: 5px 0px 0 15px;
  margin: 0 10px;
}

.report_doctor_name {
  font-size: 14px;
  color: var(--app-theme);
}

.report_time {
  font-size: 12px;
  color: black;
  text-align: start;
  font-weight: 500;
}

.cardTitles {
  width: 170px;
  height: 80px;
  padding: 0px 0 0 20px;
  box-shadow: #00000029 0px 1px 5px !important;
}

/* .tab_inactive, */
.tab_active {
  width: fit-content;
}

.ReportHead {
  margin-top: 20px !important;
  font-weight: bold;
}

.Centerline {
  width: 3px;
  background-color: #f0eeed;
  height: 35vh;
  margin-top: 73px;
  border: 1px solid #f0eeed;
  margin-left: 34px;
  /* box-shadow: inset 0px 0px 40px 40px #DBA632; */
}

.holding {
  margin: 0 !important;
  justify-content: space-around;
}

.card_Titles {
  width: 235px;
  height: 80px;
  padding: 0px 0 0 20px;
  box-shadow: #00000029 0px 1px 5px !important;
  border-radius: 33px !important;
}

.card_comment {
  /* padding: 10px; */
  box-shadow: #00000029 0px 3px 6px;
  height: 200px !important;
}

.comments_fileds {
  border: none !important;
  height: 209px !important;
}

.comment_text {
  font-size: 12px !important;
}

/* ===================== */
/* .stepper-card_comment {
    width: 275px;
    border-radius: 20px;

    padding: 10px;
    background-color: white;
    box-shadow: #00000029 0px 1px 5px;
    margin-left: 20px;
    flex: 1 1;
    text-align: start;
} */

.stepper-line_comment {
  width: 3px;
  height: 60px;
  background-color: #48bfe3;
  margin: -18px 295px -18px 0;
  opacity: 40%;
}

.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.stepper-step {
  display: flex;
  align-items: center;
}

/* .stepper-circle {
    width: 45px;
    height: 45px;
    border: 3px solid #48BFE3;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
} */

.stepper-line {
  width: 3px;
  height: 60px;
  background-color: #48bfe3;
  margin: -18px 190px -18px 0;
  opacity: 40%;
}

.stepper-card {
  padding: 10px;
  /* border: 1px solid #007bff; */
  border-radius: 20px;
  background-color: white;
  box-shadow: #00000029 0px 1px 5px;
  margin-left: 20px;
  flex: 1 1;
  width: 170px;
  text-align: start;
  /* word-break: break-word; */
  cursor: pointer;
}

.stepper-image {
  max-width: 400px;
  border: 2px solid #007bff;
  /* Change the border color to match the circle */
  border-radius: 10px;
}

.profile_age span {
  text-transform: capitalize;
}

/* .report_doctor_profile {
    height: 100%;
    width: 100%;
    border-radius: 27px;
} */

.nodata_found {
  /* padding-left: 45px; */
  padding-top: 0rem;
}

.AddButt {
  margin-top: 45px;
}

/* ===================== */

.video_iframe_div {
  height: 100%;
  width: 100%;
}

.video_iframe_div img {
  height: auto;
  width: 100%;
}

.video_popup .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  max-height: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
}

.report_name {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
.report_name_command {
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
  text-align: start;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.report_name_command_prescription {
  margin-bottom: 10px;
  /* width: 170px; */
  text-align: start;
  font-size: 14px;
  font-weight: 600;
}

.report_view {
  color: #510f30;
  font-weight: 600;
  font-size: 13px;
  margin: 0px;
  margin-top: 3px;
  cursor: pointer;
}

.report_view_more {
  display: flex;
  justify-content: space-between;
}

.requried_type {
  padding: 8.5px 14px;
  color: #ff0068;
}

.command-container {
  padding: 20px 10px;
}

.nodata-view {
  justify-content: center;
  height: 9vh;
  align-items: center;
}
.nodata-view p {
  margin-bottom: 0px !important;
}
.prescriptions-container {
  justify-content: center;
  flex-direction: unset !important;
  padding: 0px 5px;
}

.save_details_new_report {
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 40px 74px 40px 40px;
}
.add_comment_details {
  border-radius: 8px !important;
}
.comment_details {
  text-align: start;
  font-size: 19px;
  font-weight: 600;
  color: var(--app-theme);
  padding: 20px;
}
.comment_details_prescription {
  text-align: start;
  padding-top: 32px;
  font-size: 19px;
  font-weight: 600;
  color: var(--app-theme);
}

.stepper-container.command-container {
  display: flex;
  justify-content: center;
  align-items: start;
}

.stepper-horizontal {
  display: inline-flex; /* Keeps steps in a row */
  align-items: center;
  padding-left: 108px;
  padding-top: 28px;
  padding-bottom: 12px;
}

.stepper-step {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.stepper-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.stepper-circle:hover {
  transform: scale(1.05);
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}

.report_doctor_profile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.stepper-card_comment {
  text-align: center;
  margin-top: 8px;
}

.stepper-line_horizontal {
  width: 230px;
  height: 2px;
  background-color: #2f887c47;
  margin: 0 0px;
  margin-top: 25px;
}

.css-6rmpcl-MuiModal-root-MuiDialog-root .MuiDialog-container .MuiPaper-root {
  /* width: 100%;
    max-width: 94vh !important;
    border-radius: 20px;
    height: 68vh; */
}
.css-16ytuc0 .MuiDialog-container .MuiPaper-root {
  width: 100%;
  max-width: 94vh !important;
  border-radius: 20px;
  height: 68vh;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  height: 38vh !important;
  margin-top: 79px !important;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
  height: 170px !important;
}
.comment_image {
  height: 145px;
  width: 145px;
}
.success_model_body {
  padding-top: 14vh;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 16px !important;
}
.save_button_details {
  padding: 8px 33px 8px 33px;
}

.dialog_header_rep {
  display: flex !important;
  padding: 1.5rem !important;
  color: var(--app-theme) !important;
}

.dialog_header_rep .dialog_title_rep {
  color: var(--app-theme) !important;
  font-size: 20px !important;
}

.dialog_header_rep img {
  width: 20px;
  cursor: pointer;
}
.command-text-container {
  margin: 2rem 0rem 4rem 0rem;
  height: 30vh;
  box-shadow: 0px 3px 6px #2f887c5c;
  border: 1px solid #2f887c;
  border-radius: 31px;
  overflow: scroll;
  word-wrap: break-word;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.command-text-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.command-text-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.prescrition_add_details_list {
  padding-top: 0px !important;
}

.prescription_profile {
  margin-top: -31px !important;
}
.appointment_page_detailsprofile {
  margin-top: 15px !important;
}
.scrollable-stepper {
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Keeps items in a single line */
  width: 100%; /* Adjust as needed to control visible width */
  display: flex;
}

.css-1hof3tc {
  margin-top: 11vh !important;
}
.close_mark_contain {
  display: flex;
  justify-content: space-between;
}
.close_icon_contain {
  height: 21px;
  width: 21px;
  cursor: pointer;
}
