.Reportcontainer {
  display: flex;
  justify-content: center;
}

.EnterDetalis {
  width: fit-content;
  padding: 10px 0;
}

.EnterDetalis label {
  padding: 0px 0px 5px;
}

.EnterDetalis input {
  padding: 8px 5px;
  width: 450px;
  border-radius: 5px;
}

.file_upload_ {
  display: flex;
}

.text_filed {
  border: 1px solid var(--app-theme) !important;
  border-color: var(--app-theme);
  box-shadow: 0px 0px 6px -3px;
  height: 40px;
}

.Uploading_box {
  position: absolute;
  opacity: 0;
}

.success_button_row {
  margin: 0 !important;
}

.fileUpload_report {
  width: 450px !important;
}

.report_date {
  color: var(--app-theme);
}
.Reportcontainer_new {
  display: flex;
  justify-content: start;
  /* padding-top: 40px;
    padding-left: 50px; */
  margin: auto;
}
.segment_center {
  width: fit-content;
  display: flex;
  align-items: center;
}
.report_name_details {
  padding-top: 10px;
}
.fileUpload_report_new {
  width: 450px !important;
  height: 200px;
}

.fileUpload_new input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 39px;
  cursor: pointer;
}
.fileUpload_new span img {
  width: 14px;
  margin: 0px 9px;
}
.fileUpload_new p {
  /* position: absolute; */
  top: 9px;
  left: 10px;
  font-size: 16px;
  font-weight: 200;
  margin: 0px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: var(--app-theme);
  padding-top: 12px;
}
.fileUpload_new {
  background: transparent;
  border-radius: 5px;
  color: #0e0e0e;
  font-size: 1em;
  font-weight: bold;
  overflow: hidden;
  padding: 8px;
  position: relative;
  text-align: center;
  width: 100%;
  cursor: pointer;
  border: 1px dashed var(--app-theme);
  padding-top: 59px;
}
.report_name_text {
  color: var(--app-theme);
  font-weight: 600;
}
.upload_image_details {
  height: 238px;
  width: 297px;
}
.success_button_row_new {
  margin-top: 20px !important;
  margin-left: 90px !important;
}
.name_details_new {
  color: var(--app-theme);
  font-weight: 600;
  margin: 0;
}
.profile_details_prescription_new {
  border-bottom: 2px solid var(--app-theme);
  display: flex !important;
  padding-bottom: 25px;
}
.fileUpload_new input.upload {
  height: 198px;
}
.fileUpload_new img{
  height: 30px;

}