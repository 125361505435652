.css-jn69v9 {
    height: 8px !important;
    width: 8px !important;
    position: relative;
    left: 12px;
    bottom: 1px;
}

.css-honxw6 {
    height: 8px !important;
    width: 8px !important;
    position: relative;
    left: 12px;
    bottom: 1px;
}
.Icons{
    display: flex;
    justify-content: space-between;
}
.header_img_down{
    display: flex;
    align-items: center; 
    padding: 0px 8px;
    cursor: pointer;
}
.iconhead{
    height: 21px;
    cursor: pointer !;
}
.header_settle_body
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pdfBlock{
    display: block;
}
.pdf_display{
display: none;
}