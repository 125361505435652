/* -------------------- New FLow ---------------------------- */

.dashboard_header {
  top: 0;
  width: 100%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  box-shadow: #00000029 0 3px 6px;
  position: fixed;
  z-index: 3;
}

.dashboard_header_row {
  margin: 0 !important;
  display: flex;
  align-items: center;
}

.dashboard_header_content {
  display: flex;
}

.dashboard_header_logo {
  margin: 15px;
  height: 35px;
  width: 170px;
}

.dashboard_header_dropdown {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

/* .dashboard_header_dropdown_button {
    margin-top: 22px;
} */

.dashboard_header_dropdown_button:hover {
  background-color: none !important;
}

.dashboard_header_dropdown_button:active {
  background-color: none !important;
}

.dashboard_header_profile_part {
  display: flex;
  justify-content: end;
}

.dashboard_header_icons {
  display: flex;
  flex-direction: row;
}

.dashboard_header_home_icon {
  position: relative;
}

.home_image {
  height: 32px !important;
  width: 32px !important;
  margin: 11px 15px 0 15px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.notification_image {
  cursor: pointer;
  width: 32px;
}

.notification_image_header {
  background-color: #8ebcb575;
  width: 32px;
  height: 32px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  border-radius: 30px;
}

.notification_icon_image {
  width: 17px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 6px;
  left: 7px;
}

.profile_image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 9px 0px 0px 20px;
  cursor: pointer;
  border: 1px solid var(--app-theme) !important;
}

.profile_text_header {
  margin: 13px 10px 0px;
  font-weight: 600;
  font-family: Segoe UI;
}

.profile_text_name_header {
  color: #000;
  font-size: 18px;
  margin-top: 0 !important;
}

@media only screen and (min-width: 655px) and (max-width: 760px) {
  .dashboard_header_logo {
    margin: 10px 0px 10px 10px;
    height: 45px;
    width: 45px;
  }

  .dashboard_header_dropdown {
    display: flex;
    align-items: center;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 655px) {
  .dashboard_header_logo {
    margin: 10px 0px 10px 10px;
    height: 40px;
    width: 40px;
  }

  .dashboard_header_dropdown {
    display: flex;
    align-items: center;
    margin-left: 0px !important;
  }

  .dashboard_header_dropdown .css-q8hpuo-MuiFormControl-root {
    width: 140px !important;
  }
}

.home_header {
  top: 0;
  /* height: 80px; */
  width: 100%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  box-shadow: #00000029 0 3px 6px;
  position: fixed;
  z-index: 1;
}

.header_right_column {
  float: right;
}

.footer {
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  /* position: sticky; */
  bottom: 0px;
  width: 100%;
  height: 85px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 12px;
  box-shadow: #42596527 0 1px 20px;
}

.decline_msg_box {
  border-radius: 12px;
  border: 1px solid #510f30;
  width: 70%;
  height: 175px;
  display: flex;
  padding: 8px;
  font-size: 12px;
}

.rejectimage {
  width: 100px !important;
  margin-top: -7px;
  margin-right: 2px;
}

.waiting_class1 {
  width: 100%;
  padding-top: 1%;
  text-align: end;
  padding-right: 160px;
  font-size: 16px;
  color: #510f30;
}

.logo_images {
  /* margin: 10px 5px;
    height: 56px;
    width: 69.4px; */
  margin: 10px 5px;
  height: 56px;
  width: 130px;
}

.logo_images_aprove {
  margin: 10px 20px;
  height: 56px;
  width: 130px;
}

/* .header_row {
    background-color: aqua;
} */

.header_Logo {
  background-color: aquamarine;
}

/* ============= */
.flex_grow_1 {
  flex-grow: 1;
}

.profile_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 5px;
  position: relative;
  z-index: 1;
}

.profile_img_aprove {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 5px;
  position: relative;
  z-index: 1;
  margin: 5px 20px;
  cursor: pointer;
  padding: 10px;
}

.video-icon2 {
  position: absolute;
  top: 46px;
  left: 47px;
  z-index: 0;
}

.start_button {
  padding: 3px 19px 3px 19px;
  border-radius: 20px;
  font-size: 16px;
  color: white;
  background-color: #510f30;
  border-color: black;
  font-family: titillium web !important;
  font-weight: 500 !important;
}

/* ============= */

.head_right_content {
  margin-right: 0 !important;
}

.profile_name_header {
  color: var(--app-theme);
  font-size: 18px;
  margin: 12px 0 0 0;
}

.username {
  margin-top: 0 !important;
}

.fa-bell {
  margin-top: 20px;
}

.row_content_ {
  margin: 0 !important;
}

.row_content_aprove {
  margin: 0 !important;
  display: flex;
  align-items: center;
}

.dropdown_button {
  margin-top: 22px;
}

.dropdown_button:hover {
  background-color: none !important;
}

.dropdown_button:active {
  background-color: none !important;
}

.support-container {
  position: relative;
}

.support_images {
  height: 32px !important;
  width: 32px !important;
  margin: 27px 0 0 15px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.video-icon {
  position: absolute;
  top: 34px;
  left: 23px;
  z-index: 0;
}

.fix_dropdown_aprove {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.fix_dropdown .css-iyh4ih-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  margin: 0 !important;
}

.header_icons {
  display: flex;
  flex-direction: row;
}

.MuiInputBase-inputSizeSmall,
.MuiSvgIcon-fontSizeMedium {
  /* color: #510F30 !important; */
  /* color: var(--app-sub-header) !important; */
  color: #000 !important;
  border: var(--app-sub-header) !important;
}

.profile_text {
  align-items: center; 
  display: flex;
  flex-direction: column;
  margin: 0px 20px 0px 40px;
}

.profile_text_aprove {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0px 20px 0px 40px;
}

.header_right_part {
  display: flex;
  justify-content: end;
}

.verification_main_div {
  /* margin-top: 50px; */
  margin-top: 150px;
  text-align: center;
}

.verification_main_waiting {
  margin-top: 40vh;
  text-align: center;
}

.step_head_img {
  width: 8vw;
  height: 15vh;
  margin-top: 2rem;
  cursor: pointer;
  position: relative;
  z-index: 9;
  border: 2px solid var(--app-theme);
  padding: 10px;
  border-radius: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
}

.stepper_line_show .MuiStepIcon-completed {
  fill: var(--app-theme) !important;
  height: 40px !important;
  width: 40px !important;
  margin-top: -2px;
}

.stepper_line_show .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root .Mui-completed {
  height: 40px !important;
  width: 40px !important;
  margin-top: -7px;
  color: var(--app-theme) !important;
}

.stepper_line_show .css-4ff9q7 .Mui-completed {
  color: var(--app-theme) !important;
  height: 40px !important;
  width: 40px !important;
  margin-top: -7px !important;
}

.stepper_line_show .MuiSvgIcon-fontSizeMedium {
  color: var(--app-theme) !important;
  height: 40px !important;
  width: 40px !important;
  margin-top: -7px !important;
  /* border: #CCCCCC !important; */
}

/* =================== */
.Mui-disabled svg,
.Mui-active svg {
  border: 1px solid grey !important;
  border-radius: 100%;
  height: 40px !important;
  width: 40px !important;
  margin-top: -7px;
}

.Mui-disabled svg,
.Mui-active svg,
.stepper_line_show .MuiStepIcon-active {
  border: 1px solid grey !important;
  border-radius: 100%;
  height: 40px !important;
  width: 40px !important;
  /* margin-top: 0px; */

  margin-top: -9px;
  /* margin-top: 1px; */
}

.Mui-disabled circle,
.Mui-active circle {
  fill: white;
}

.play_img {
  position: absolute;
  cursor: pointer;
  /* top: 33vh; */
  /* top: 36vh; */
  /* top: 50vh; */
  margin-left: calc(1rem + -35px);
  bottom: 0px;
  /* z-index: -1; */
}

.disable_card {
  opacity: 0.5;
}

.notification_not_icon {
  width: 31px;
  height: 31px;
  margin-top: 28px;
  cursor: pointer;
}

.waiting_class {
  width: 100%;
  padding-top: 1%;
  text-align: end;
  padding-right: 150px;
  font-size: 16px;
  color: #510f30;
}

.congrats_text {
  width: 100%;
  padding-top: 1%;
  text-align: end;
  padding-right: 8.5%;
  font-size: 16px;
  color: #510f30;
}

/* 
.stepper_line_showed {
    fill: var(--app-theme) !important;
    height: 40px !important;
    width: 40px !important;
    margin-top: -2px;
    display: flex;
} */
.three_step {
  color: #510f30 !important;
  margin-bottom: 0px !important;
}

.css-z7uhs0-MuiStepConnector-line {
  display: block !important;
  border-color: #510f30 !important;
  border-top-style: solid !important;
  border-top-width: 3px !important;
}

.css-95m0ql {
  display: block !important;
  border-color: #510f30 !important;
  border-top-style: solid !important;
  border-top-width: 3px !important;
}

.three_step_head {
  padding: 10px 0px;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.14),
    0 0px 7px 0 rgba(0, 0, 0, 0.12) !important;
  color: #510f30;
  font-weight: 400 !important;
  width: 95%;
  margin: auto;
  margin-bottom: 40px !important;
}

.hand_icon {
  height: 20px;
  width: 30px;
}

.click_div {
  display: flex;
  justify-content: end;
  padding-right: 30px;
  padding: 0px 25px 0px 0px;
}

.click_div p {
  color: #510f30;
  font-size: 9px;
  margin-top: 0.8%;
}

.notification_images {
  cursor: pointer;
  width: 32px;
}