/* .cancel_slots {
    padding: 20px 0px;
    display: flex;
    border: 1px solid #000;
} */

.cancel_slots_card {
    padding: 15px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    margin: 10px 0px;
}

.cancel_slots_date {
    margin: 0;
    padding-top: 10px;
}

.Txtbox_alin {
    margin: 12px 12px;
}

.cancel_slots_button {
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 2rem;
    position: sticky;
}

.cancel_slots_confirm_button {
    background-color: var(--app-theme) !important;
    color: #ffffff !important;
    border-radius: 23px !important;
    padding: 7px 25px !important;
    text-transform: capitalize !important;
}

.cancel_slots_cancel_button {
    background-color: #FF0068 !important;
    color: #fff !important;
    border-radius: 23px !important;
    margin-right: 10px !important;
    padding: 7px 25px !important;
    text-transform: capitalize !important;
}

.cancel_slots_show_button {
    background-color: #510F3040 !important;
    color: #83AF41 !important;
}

.cancel_slots_hide_button {
    background-color: var(--app-theme) !important;
    color: #ffffff !important;
}

.cancel_hide_slots {
    display: flex;
    /* justify-content: space-evenly; */
}

.availability_booked .MuiSvgIcon-fontSizeMedium {
    color: #E12F2F !important;
    border: #E12F2F !important;
}

.availability_selected .MuiSvgIcon-fontSizeMedium {
    color: var(--app-theme) !important;
    border: #CCCCCC !important;
}

.availability_blocked .MuiSvgIcon-fontSizeMedium {
    color: #0000FF !important;
    border: #CCCCCC !important;
}

.cancel_slots_card_one {
    padding: 10px;
    border: 1px solid #E4E4E4;
    margin: 10px 200px;
}

.slots_name {
    display: flex;
    justify-content: center;
    gap: 18vw;
}

.slots_name .thirdDIv {
    position: relative;
    left: -1rem;
}

.check {
    position: relative;
    border: 1px solid #d0cdcd;
    padding: 11px;
}

.slotcheckone {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    height: 14px;
}

.slotcheckone i {
    color: black;
    font-size: 20px;
}






.slot_pane_content .MuiSvgIcon-fontSizeMedium {
    color: var(--app-theme) !important;
    border: #CCCCCC !important;
}

.slot_pane_content {
    padding: 12px;
    margin: 5px 10px 5px 5px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
}

.pane_row_one {
    display: flex;
    justify-content: space-between;

}

.slot_checkbox_main .MuiSvgIcon-root {
    width: 1.15em;
    height: 1.15em;
}

.slot_checkbox_main.MuiCheckbox-colorSecondary.Mui-checked {
    color: #86b149;
}

.slot_date {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.showslot_button {
    background-color: #E0EBCF !important;
}

.showslot_button .MuiButton-label {
    font-size: 12px !important;
    color: #86b149 !important;
    text-transform: capitalize;
}

.anticon.anticon-right.ant-collapse-arrow {
    display: none;
}

.inner_pane_content {
    /* display: flex; */
    /* justify-content: space-between; */
    /* width: 21.5%; */
    display: flex;
    align-items: center;
}

.inner_pane_content p {
    padding: 0px;
}

.botton_Cart_Create_Manage {
    color: #ffffff !important;
    border-radius: 25px !important;
    background-color: #FF0068 !important;
    padding: 8px 30px;
    background: #FF0068 0% 0% no-repeat padding-box;
    border: 1px solid #FF0068;
    /* width: 100px; */
    font-size: 16px;
    font-weight: 500;
}

.hidden_slots_pane {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    /* padding: 25px; */
}

.slot-width {
    width: 100% !important;
    margin: 5px auto;
}

.hidslot_check {
    display: flex;

}

.slot_cancel {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.pane_row_two {
    display: flex;
    justify-content: space-between;

}

.btn-background_1 {
    background-color: var(--app-theme) !important;
    font-size: 15px !important;
    color: white !important;
    text-transform: capitalize !important;
    transition: none !important;
}

.btn-background_2 {
    background-color: #510F3040 !important;
    font-size: 15px !important;
    color: var(--app-theme) !important;
    text-transform: capitalize !important;
    transition: none !important;
}

.hiddenslot_checkbox.MuiCheckbox-colorSecondary.Mui-checked {
    color: #86b149;

}

.hiddenslot_checkbox.MuiButtonBase-root {
    padding: 8px;
}

.cancel_slot_buttons {
    display: flex;
    justify-content: flex-end;
    margin: 5px;
    padding: 10px;
}

.slotcancel_button {
    background-color: #B6B6B6 !important;
    color: black !important;
    font-size: 12px !important;
    padding: 5px !important;
    margin: 5px !important;
    text-transform: capitalize !important;
}

.slot_confrim_button {
    background-color: #86b149 !important;
    color: white !important;
    font-size: 12px !important;
    padding: 5px !important;
    margin: 5px !important;
    text-transform: capitalize !important;
}




@media (min-width: 960px) {

    .hidslot_check {
        border: 1px solid #e4e4e4;
        display: flex !important;
        flex-basis: 100% !important;
        flex-wrap: wrap !important;
        max-width: 60% !important;
        padding: 0px 1px;
    }
}

.image_body_sizes {
    height: 75px;
    width: 75px;
}