.RevenHeader {
    font-size: 25px;
    font-weight: 500;
    font-family: 'T';
}

.chartbox {
    width: 90%;
    margin: auto;
    padding: 20px;
}
.menucol{
    /* height: 115%; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.header_settle {
    margin: auto;
    width: 97%;
    position: relative;
    right: 0px !important;
    left: 0px !important;
    background-color:#e7e7e7aa !important;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    
}
.Scrool_bar_dash{
    /* overflow-y: auto !important; */
    /* overflow-x: auto !important; */
    overflow: hidden;
}
.header_settle_body
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}




  /* toggler */

  .RS_toggler{
    width: 100%;
    display: inline-grid;
    align-items: center;
    justify-content: end;
    padding: 20px 20px;
  }.switches-container {
     box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
     border-radius: 30px; 
     width: fit-content;
     position: relative;
     display: flex;
     padding: 0;
     position: relative;
     background: white;
     line-height: 26px;
     border-radius: 3rem;
     margin-left: auto;
     margin-right: auto;
  }.switches-container input {
     visibility: hidden;
     position: absolute;
     top: 0;
  }.switches-container label {
     width: fit-content;
     padding:1px 8px;
     margin: 0;
     text-align: center;
     cursor: pointer;
     color: #510f30;
     font-size: 14px;
  }.switch-wrapper {
     position: absolute;
     top: 0;
     bottom: 0;
     width: 50%;
     z-index: 3;
     transition: transform .5s cubic-bezier(.77, 0, .175, 1);
  }.switch {
     border-radius: 3rem;
     background: #510f30;
     height: 100%;
     color: white !important;
  }.switch div {
     width: 100%;
     text-align: center;
     opacity: 0;
     display: block;
     color: white;
     transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
     will-change: opacity;
     position: absolute;
     top: 0;
     left: 0;
     font-size: 12px;}
     
  /* toggler end */



  .RS_Sidemenu{
    flex: 0 0 20%;
  }
  .RS_mainpage{
    flex: 0 0 80%;
  }