.daterangeMaster {
  z-index: 999;
  position: absolute;
}

.daterangeMaster .rdrDateRangePickerWrapper {
  border: 1px solid #c3bbbb;
  border-radius: 3px;
}

.daterangeMaster .rdrDefinedRangesWrapper,
.daterangeMaster .rdrDateRangeWrapper {
  border-radius: 3px;
}

.daterangeMaster .rdrDefinedRangesWrapper {
  border: 1px solid #c3bbbb;
  border-left: none;
  background-color: #eff2f7;
}

.daterangeMaster .rdrDefinedRangesWrapper button {
  background-color: inherit;
  /* color: white; */
  /* border-bottom: 1px solid; */
}

/* .daterangeMaster .rdrCalendarWrapper {
    border: 1px solid #c3bbbb;
    border-radius: 5px;
}

.daterangeMaster .rdrStartEdge,
.daterangeMaster .rdrInRange,
.daterangeMaster .rdrEndEdge{
    color: var(--app-theme) !important;
}


.daterangeMaster .rdrDayStartPreview,
.daterangeMaster .rdrDayInPreview,
.daterangeMaster .rdrDayEndPreview {
    color: #82AF3F !important;
}

.daterangeMaster .rdrWeekDay{
    color: var(--app-theme);
} */

/* Range Picker Common Css*/

.rangePicker_Master {
  /* margin: 10px 0px;
    height: 50px;
    border: 1px solid #e4dede; */
  display: flex;
  align-items: center;
  /* margin-right: 45px; */
}

.rangePicker_Label {
  margin-left: 30px;
  margin-right: 10px;
  color: rgb(76, 74, 74);
  font-size: 18px;
  /* font-weight: 500; */
  text-transform: none;
  /* font-family: titillium web !important; */
  font-family: TitilliumWeb-Regular;
  width: 130px;
}

.rangePicker_Master .formdiv {
  margin-top: 18px;
  height: 51.5px;
}

.rangePicker_Master .formdiv .brdrcls {
  height: 35px;
  font-size: 13px;
  padding-inline-start: 15px !important;
  text-overflow: ellipsis;
  padding: 4px 30px !important;
  font-weight: 500 !important;
  width: 250px;
  text-overflow: ellipsis;
}

.rangePicker_Master input::placeholder {
  /* Chrome/Opera/Safari */
  color: #ccc;
}

.rangePicker_Master input {
  cursor: pointer;
}

.rangePicker_Calendericon {
  z-index: 9999999;
  color: gray;
  position: relative;
  right: 25px;
  bottom: 2px;
}

.rangePicker_Calendericon:hover {
  background-color: #e6dfdf;
  border-radius: 10px;
  cursor: pointer;
}

.rangePicker_InpputIconAlign {
  display: flex;
  align-items: center;
}

.rangePicker_cancelbtn {
  font-size: 12px !important;
  background-color: #b3b3b3 !important;
  color: #333 !important;
  margin-right: 20px !important;
  border-radius: 4px !important;
  height: 25px;
}

.rangePicker_okbtn {
  background-color: var(--app-theme) !important;
  color: #fff !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  height: 25px;
  margin-right: 20px !important;
}

.rangePicker_btncontainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #c3bbbb;
  height: 35px;
  background-color: #eff2f7;
  border-top: none;
}

.daterangeMaster .rdrMonthAndYearWrapper {
  height: 35px;
  padding-top: 0px;
}

.daterangeMaster .rdrDefinedRangesWrapper {
  width: 185px;
  border-bottom: none;
  border-top: none;
  font-size: 13px;
}

.daterangeMaster .rdrCalendarWrapper {
  font-size: 13px;
}

.rangePicker_positionCenter {
  position: relative;
  z-index: 1300;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
}

@media screen and (max-width: 883px) {
  .daterangeMaster .rdrCalendarWrapper {
    font-size: 9px;
  }
}

@media screen and (max-width: 968px) {
  .rangePicker_Label {
    width: 17%;
  }
}

@media screen and (max-width: 860px) {
  .rangePicker_Master .brdrcls {
    padding-inline-start: 5px !important;
    padding-inline-end: 30px !important;
  }

  /* .rangePicker_Calendericon {
    left: 17px;
  } */
}

@media screen and (max-width: 800px) {
  .rangePicker_Label {
    font-size: 12px;
  }

  .rangePicker_Master .brdrcls {
    /* width: 115% !important; */
    padding-inline-start: 5px !important;
  }

  /* .rangePicker_Calendericon {
    left: 10px;
  } */
}

.range_picker_reduce .rangePicker_Master {
  margin-right: 5px !important;
}