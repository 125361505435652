.crdcls1 {
    flex: 0 0 calc(18.66% - 10px);
}

.Crdhead1 {
    color: #510f30 !important;
    display: flex;
    justify-content: center;
    gap: 4px;
    font-weight: 500 !important;
    height: 30px;
    align-items: center;
}
.Scrool_bar_dash{
    /* overflow-y: auto !important; */
    /* overflow-x: hidden !important; */
    overflow: hidden;
}
.Card1 {
    cursor: pointer;
    height: 18vh;
    width: 28vh;
    border-radius: 10px !important;
    background: rgb(138, 14, 76);
    background: linear-gradient(112deg, rgba(138, 14, 76, 1) 0%, rgba(81, 15, 48, 1) 50%, rgba(81, 15, 48, 1) 100%);
    display: flex;
    align-items: center;
}

.Card2 {
    cursor: pointer;
    height: 18vh;
    width: 28vh;
    border-radius: 10px !important;
    background: rgb(6, 215, 202);
    background: linear-gradient(112deg, rgba(6, 215, 202, 1) 0%, rgba(0, 164, 154, 1) 50%, rgba(0, 164, 154, 1) 100%);
    display: flex;
    align-items: center;
}

.Card3 {
    cursor: pointer;
    height: 18vh;
    display: flex;
    align-items: center;
    width: 28vh;
    border-radius: 10px !important;
    background: rgb(244, 156, 122);
    background: linear-gradient(112deg, rgba(244, 156, 122, 1) 0%, rgba(244, 127, 81, 1) 50%, rgba(244, 156, 122, 1) 100%);
}

.Card4 {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 18vh;
    width: 28vh;
    border-radius: 10px !important;
    background: rgb(6, 215, 202);
    background: linear-gradient(112deg, rgba(6, 215, 202, 1) 0%, rgba(0, 164, 154, 1) 50%, rgba(0, 164, 154, 1) 100%);
}

.contentcrd {
    color: #fff;
    padding: 10px !important;
    padding-top: 10px !important;
    font-size: 16px;
    border-radius: 10px;
    width: 100%;
}

.KWD_font {
    font-size: 10px;
}

.border_crd {
    border-bottom: 1px solid #fff;
}

.chartbox {
    width: 90%;
    margin: auto;
    padding-bottom: 25px;
    margin-bottom: 9%;
}
.shadow_Class {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.overlay_Class {
    opacity: 0.2 !important;
}

/* @media only screen and (max-width: 600px) {
    .Card1 {
        height: 10%;
        width: 15%;
    }
  } */