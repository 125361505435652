.footer_icon_row {
    display: flex;
    justify-content: space-around;
}

.footer_column {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 17vw;
}

.icon_footer {
    height: 20px !important;
    width: auto !important;
}

.menu_active {
    color: var(--app-theme);
    font-weight: 600;
}

.menu_unactive {
    color: #510F30;
    font-size: 13px;
    font-weight: 600;
}

.active-link {
    background-image: url('../../assets/Doctor_web_Icon/bfrect.png');
    background-size: 76px;
    background-repeat: no-repeat;
    position: relative;
    width: 69px;
    background-position-y: -4px;
    padding: 8px 0px 0px 6px;
}

.unactive-link {
    position: relative;
    width: 69px;
    padding: 8px 0px 0px 6px;
    z-index: 1;
}
.active-link img{
    cursor: pointer;
}
.unactive-link:hover{
    cursor: pointer;
}